import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const office = (state = initialState.offices, action) => {
  let officeIndex,
    offices = null
  let updatedOffices
  switch (action.type) {
    case types.CREATE_OFFICE_SUCCESS:
      action.payload.total_items = 0
      action.payload.assigned_items = 0
      action.payload.free_items = 0
      action.payload.dead_items = 0
      return { ...state, offices: [...state.offices, action.payload] }

    case types.CREATE_OFFICE_FAILURE:
      return state

    case types.FETCH_OFFICE_SUCCESS:
      return { ...state, offices: action.payload }

    case types.FETCH_OFFICE_FAILURE:
      return state

    case types.FETCH_SELECT_OFFICES_SUCCESS:
      return { ...state, selectOffices: action.payload }

    case types.EDIT_OFFICE_SUCCESS:
      officeIndex = state.offices.findIndex((office) => office.id === action.payload.id)

      offices = [...state.offices]
      offices[officeIndex].address = action.payload.address
      offices[officeIndex].city_name = action.payload.city_name
      offices[officeIndex].archive = action.payload.archive

      return { ...state, offices: [...offices] }

    case types.EDIT_OFFICE_FAILURE:
      return state

    case types.OFFICE_BULK_UPDATE_SUCCESS:
      updatedOffices = state.offices.map((office) =>
        !office.archive && action.payload.bulk_archived_offices.some((payloadOffice) => payloadOffice.id === office.id)
          ? { ...office, archive: true }
          : office
      )

      return { ...state, offices: updatedOffices }

    case types.DELETE_OFFICE_SUCCESS:
      if (action.payload.id) {
        officeIndex = state.offices.findIndex((office) => office.id === action.payload.id)
      }

      if (officeIndex === null) return state

      offices = [...state.offices]
      offices.splice(officeIndex, 1)

      return { ...state, offices: [...offices] }

    case types.DELETE_OFFICE_FAILURE:
      return state

    default:
      return state
  }
}
