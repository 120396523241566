import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const claimsBlacklist = (state = initialState.claimsBlacklist, action) => {
  let claimsBlacklistIndex,
    claimsBlacklist = null

  switch (action.type) {
    case types.CREATE_CLAIMS_BLACKLIST_SUCCESS:
      return { ...state, claimsBlacklist: [...state.claimsBlacklist, action.payload.blacklist] }

    case types.CREATE_CLAIMS_BLACKLIST_FAILURE:
      return state

    case types.FETCH_CLAIMS_BLACKLIST_SUCCESS:
      return { ...state, claimsBlacklist: action.payload }

    case types.FETCH_CLAIMS_BLACKLIST_FAILURE:
      return state

    case types.EDIT_CLAIMS_BLACKLIST_SUCCESS:
      claimsBlacklistIndex = state.claimsBlacklist.findIndex(
        (blacklistedClaim) => blacklistedClaim.id === action.payload.blacklist.id
      )

      claimsBlacklist = state.claimsBlacklist
      claimsBlacklist[claimsBlacklistIndex] = action.payload.blacklist

      return { ...state, claimsBlacklist: [...claimsBlacklist] }

    case types.EDIT_CLAIMS_BLACKLIST_FAILURE:
      return state

    case types.DELETE_CLAIMS_BLACKLIST_SUCCESS:
      if (action.payload.id) {
        claimsBlacklistIndex = state.claimsBlacklist.findIndex(
          (blacklistedClaim) => blacklistedClaim.id === action.payload.id
        )
      }

      claimsBlacklist = [...state.claimsBlacklist]
      claimsBlacklist.splice(claimsBlacklistIndex, 1)

      return { ...state, claimsBlacklist: [...claimsBlacklist] }

    case types.DELETE_CLAIMS_BLACKLIST_FAILURE:
      return state

    default:
      return state
  }
}
