import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const report = (state = initialState.reports, action) => {
  switch (action.type) {
    case types.SET_REPORTS_TAB:
      return { ...state, tab: action.payload.tab }

    case types.FETCH_OVERVIEW_REPORT_SUCCESS:
      return {
        ...state,
        overviewReport: action.payload.overview,
        employmentStatus: action.payload.employment_status,
        departments: action.payload.departments,
        resources: action.payload.resources,
        totalUsers: action.payload.total_users,
        totalOnboardedUsers: action.payload.total_onboarded_users,
        totalOffboardedUsers: action.payload.total_offboarded_users
      }

    case types.FETCH_OVERVIEW_REPORT_FAILURE:
      return state

    case types.FETCH_LEAVE_REPORT_SUCCESS:
      return {
        ...state,
        leaveReport: action.payload
      }

    case types.FETCH_LEAVE_REPORT_FAILURE:
      return state

    case types.FETCH_TICKET_REPORT_SUCCESS:
      return {
        ...state,
        ticketReport: action.payload
      }

    case types.FETCH_TICKET_REPORT_FAILURE:
      return state

    case types.FETCH_FEEDBACK_REPORT_SUCCESS:
      return {
        ...state,
        feedbackReport: action.payload
      }

    case types.FETCH_FEEDBACK_REPORT_FAILURE:
      return state

    case types.FETCH_WORKFLOW_REPORTS_DATA_SUCCESS:
      return action.flag === 'workflow_stats'
        ? { ...state, workflowReportOverviewData: action.payload }
        : action.flag === 'department_tasks_stats'
          ? { ...state, workflowReportGraphData: action.payload }
          : { ...state, workflowReportTableData: action.payload }

    case types.UPDATE_WORKFLOW_REPORT_DATA_SUCCESS:
      return { ...state, workflowStatsUpdatedAt: action.payload.updated_at }

    case types.UPDATE_TICKET_REPORT_DATA_SUCCESS:
      return { ...state, ticketStatsUpdatedAt: action.payload.updated_at }

    case types.GET_TICKET_REPORTS_LAST_UPDATE_SUCCESS: {
      return { ...state, ticketStatsUpdatedAt: action.payload.synced_at }
    }

    case types.CREATE_PEOPLE_CUSTOM_REPORT_SUCCESS:
      return { ...state, peopleCustomReports: [...action.payload, ...state.peopleCustomReports] }

    case types.FETCH_PEOPLE_CUSTOM_REPORTS_SUCCESS:
      return { ...state, peopleCustomReports: action.payload }

    case types.DELETE_PEOPLE_CUSTOM_REPORT_SUCCESS:
      return {
        ...state,
        peopleCustomReports: state.peopleCustomReports.filter((report) => report.id !== action.payload.id)
      }

    case types.DOWNLOAD_PEOPLE_CUSTOM_REPORT_SUCCESS:
      return { ...state, downloadedCustomReport: action.payload }

    case types.FETCH_CUSTOM_REPORT_STATS_SUCCESS:
      return { ...state, customReportStats: action.payload }

    case types.FETCH_REVIEW_REPORT_STATS_SUCCESS:
      return { ...state, reviewReports: action.payload }

    case types.FETCH_TICKET_CARDS_REPORT_SUCCESS:
      return { ...state, ticketCards: action.payload }

    case types.FETCH_TICKET_DEPARTMENT_REPORT_SUCCESS:
      return { ...state, ticketDepartments: action.payload }

    case types.FETCH_TICKET_CATEGORIES_REPORT_SUCCESS:
      return { ...state, ticketCategories: action.payload }

    case types.FETCH_TICKET_ASSIGNEE_REPORT_SUCCESS:
      return { ...state, ticketAssignee: action.payload }

    default:
      return state
  }
}
