export const selectWrapperStyle = (props) => {
  return {
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: '#667085',
      fontSize: '14px',
      fontStyle: 'normal',
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontWeight: 400
    }),
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      padding: '8px 0px'
    }),
    indicatorContainer: (baseStyles) => ({
      ...baseStyles,
      padding: '3px',
      cursor: 'pointer'
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      width: 0,
      display: 'none'
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isDisabled ? '#98A2B3' : '#667085',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      '& .MuiChip-root .MuiChip-label': {
        display: 'flex',
        alignItems: 'center'
      }
    }),
    control: (baseStyles, state) => {
      let isDefaultLabel = state.selectProps.labelDefault && state.hasValue && !state.isDisabled
      return {
        ...baseStyles,
        padding: 0,
        borderRadius: '8px',
        pointerEvents: 'auto',
        background: state.isDisabled ? 'var(--Gray-50, #F9FAFB)' : '#ffffff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        fontFamily: ['Inter', 'sans-serif'].join(','),
        margin: 0,
        minHeight: '44px !important',
        borderColor:
          (state.isFocused && !props.meta?.error) || isDefaultLabel
            ? 'var(--primary-300, #9ED9BC)'
            : props.meta?.touched && props.meta?.error
              ? 'var(--error-300, #FDA29B)'
              : '#D0D5DD',
        boxShadow:
          state.isFocused && !props.meta?.error
            ? '0px 0px 0px 4px #ECFDF3, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
            : props.meta?.touched && props.meta?.error && state.isFocused
              ? '0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
              : '',
        '&:hover': {
          borderColor: state.isFocused && 'var(--primary-50, #DFEEE9)'
        }
      }
    },
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 1000
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      background: 'var(--primary-50, #DFEEE9)',
      padding: '2px 4px',
      borderRadius: '16px'
    }),
    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      color: '#74CA9E',
      padding: '4px',
      borderRadius: '16px',
      '&:hover': {
        background: 'var(--primary-50, #DFEEE9)'
      }
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: '#ffffff',
      padding: 0,
      '&:active': {
        background: 'rgba(30, 174, 99, 0.05)'
      },
      '& .multi-option': {
        backgroundColor:
          state.isFocused && !state.isSelected ? 'rgba(30, 174, 99, 0.05)' : state.isSelected ? '#5FC490' : '#ffffff',
        color: state.isSelected ? '#ffffff' : '#667085',
        borderRadius: '4px',
        margin: '4px',
        padding: '4px 8px'
      }
    })
  }
}
