import { cloneDeep, isEmpty, startCase } from 'lodash'
import moment from 'moment'
import { createTextMask } from 'redux-form-input-masks'

import { capitalize, checkArrayPresence } from 'helpers/applicationHelper'
import { formatDate, getCurrentDate } from 'helpers/datesHelper'
import { checkPermission } from 'helpers/permissionsHelper'

import { checkHasAccessToUnits, formatObjectIntoOptions } from './applicationHelper'
import { ONBOARDING_ID_PREFIX } from './constants'

const employmentTypeValue = { full: 1, partial: 2, contract: 3, commission: 4, probation: '0' }
export const mappedEmploymentTypes = (employment_types) => {
  const options = formatObjectIntoOptions(employment_types)
  return options.map((item) => ({
    value: employmentTypeValue[item.value],
    label: item.label
  }))
}

export const userFilterOptions = (userPermissions, isProfileOfCurrentUser = false) => [
  {
    label: 'View Claims',
    value: 'view_claims',
    isAccess: !checkPermission(userPermissions, 'claims', 'general', 'admin_view_all_claims').isNone()
  },
  {
    label: 'View Leaves',
    value: 'view_leaves',
    isAccess:
      checkPermission(userPermissions, 'leaves', 'general', 'view_user_leave_summary').isFull() &&
      !checkPermission(userPermissions, 'leaves', 'general', 'admin_view_all_leaves').isNone()
  },
  {
    label: 'View Projects',
    value: 'view_projects',
    isAccess: !checkPermission(userPermissions, 'projects', 'general', 'manage_projects').isNone()
  },
  {
    label: 'View Timeline',
    value: 'view_timeline',
    isAccess:
      (checkPermission(userPermissions, 'people', 'users', 'view_sensitive_information').isFull() ||
        isProfileOfCurrentUser) &&
      !checkPermission(userPermissions, 'projects', 'general', 'manage_projects').isNone()
  }
]

export const employmentStatusGroupBtns = [
  {
    id: 3,
    label: 'Commission Agent',
    value: 'commission'
  },
  {
    id: 2,
    label: 'Contract',
    value: 'contract'
  },
  {
    id: 0,
    label: 'Full',
    value: 'full'
  },
  {
    id: 1,
    label: 'Partial',
    value: 'partial'
  }
]

export const USER_ROLES = ['Manager', 'Lead', 'Buddy', 'Reporting To']

export const accountNumberMask = createTextMask({
  pattern: 'AA99AAAA9999999999999999',
  placeholder: '_',
  guide: false
})

export const phoneMask = createTextMask({
  pattern: '9999-9999999',
  placeholder: '_',
  guide: false
})

export const shirtSizes = {
  small: 0,
  medium: 1,
  large: 2,
  xl: 3,
  xxl: 4,
  threexl: 5,
  fourxl: 6,
  fivexl: 7
}

export const maritalStatuses = {
  single: 0,
  married: 1,
  widowed: 2,
  divorced: 3,
  separated: 4
}

export const bloodGroups = {
  unknown: 0,
  a_negative: 1,
  a_positive: 2,
  b_negative: 3,
  b_positive: 4,
  o_negative: 5,
  o_positive: 6,
  ab_negative: 7,
  ab_positive: 8
}

export const genders = { male: 0, female: 1, other: 2 }

export const userRelations = { spouse: 0, son: 1, daughter: 2 }

export const replaceUnderscoreWithSpace = (input) => input.replace('_', ' ')

export const convertToUppercase = (input) => input.toUpperCase()

export const bloodGroupsList = (bloodGroups) =>
  Object.keys(bloodGroups).map((bloodGroup) => ({
    label: replaceUnderscoreWithSpace(bloodGroup).toUpperCase(),
    value: bloodGroup
  }))

export const gendersList = (genders) => Object.keys(genders).map((key) => ({ label: key.titleize(), value: key }))

export const userRelationsList = (userRelation) =>
  Object.keys(userRelation).map((key) => ({ label: key.titleize(), value: key }))

export const shirtSizesList = (shirtSizes) =>
  Object.keys(shirtSizes).map((shirtSize) => ({
    label: shirtSize.replace('three', '3').replace('four', '4').replace('five', '5').toUpperCase(),
    value: shirtSize
  }))

export const getUserStatus = (blocked, deleted, clearanceTime) => {
  if (blocked && !deleted) return 'Blocked'
  else if (deleted) return clearanceTime ? 'Offboarded' : 'Pending Clearance'
  else return 'Offboarding'
}

export const maritalStatusList = (maritalStatuses) =>
  Object.keys(maritalStatuses).map((maritalStatus) => ({
    label: capitalize(maritalStatus),
    value: maritalStatus
  }))

export const renderShirtSize = (shirtSize) =>
  shirtSize.replace('three', '3').replace('four', '4').replace('five', '5').toUpperCase()

export const nullIfEmpty = (value) => (value === '' ? null : value)

export const decorateRequiredFieldTitle = (fieldName, value) => `${fieldName}${value ? ' *' : ''}`

export const formatPhoneNumber = (number) => number.substring(0, 4) + '-' + number.substring(4, number.length)

export const formatDataToSelectableOptions = (data, value, label) => {
  let formatedArray = []

  if (data.length !== 0) {
    data.forEach((record) => {
      formatedArray.push({ value: record[value], label: record[label] })
    })
  }

  return formatedArray
}

export const renderTechnologiesList = (technologies) =>
  technologies?.map((tech) => <li key={tech.value}>{tech.label}</li>)

export const supportStaffOptions = [
  { label: 'Reporting To', value: 11 },
  { label: 'Joining Date Range', value: 5 },
  { label: 'Designation', value: 2 },
  { label: 'Employment Type', value: 9 }
]

export const usersStatusOptions = [
  { label: 'Offboarded Users(Resigned)', value: 'offboarded_resigned' },
  { label: 'Offboarded Users(Terminated)', value: 'offboarded_terminated' },
  { label: 'Offboarding Users(Resigned)', value: 'offboarding_resigned' },
  { label: 'Offboarding Users(Terminated)', value: 'offboarding_terminated' },
  { label: 'Pending Clearance Users', value: 'pending_clearance' }
]

export const userTypeOptions = [
  { label: 'Simple User', value: 'simple_users' },
  { label: 'Operation Staff User', value: 'operation_staff_users' }
]

export const organoChartFilterOptions = [
  { label: 'Reporting To', value: 'reporting_to' },
  { label: 'Lead', value: 'lead' },
  { label: 'PM', value: 'manager' }
]

export const userTabs = [
  {
    value: '/people',
    label: 'All users',
    href: '/people'
  },
  {
    value: '/people/team-management',
    label: 'Team Management',
    href: '/people/team-management'
  },
  {
    value: '/people/employee-offboarding',
    label: 'Employee Offboarding',
    href: '/people/employee-offboarding'
  }
]

export const isPeopleTabs = (path) =>
  ['/people', '/people/team-management', '/people/employee-offboarding'].includes(path) || false

export const userExperienceOptions = [
  { label: '> 3 Month', value: '3' },
  { label: '> 6 Month', value: '6' },
  { label: '> 9 Month', value: '9' },
  { label: '> 12 Month', value: '12' },
  { label: '> 15 Month', value: '15' },
  { label: '> 18 Month', value: '18' },
  { label: '> 24 Month', value: '24' },
  { label: '> 30 Month', value: '30' },
  { label: '> 36 Month', value: '36' },
  { label: '> 42 Month', value: '42' },
  { label: '> 48 Month', value: '48' }
]
export const renderSubordinatesTitles = (title) => {
  if (title.length > 0) {
    switch (title) {
      case 'Buddy':
        return 'Buddy of'
      case 'Reports to':
        return 'Reportee of'
      case 'Manager':
        return 'Manager of'
      case 'Lead':
        return 'Lead of'
      default:
        return title
    }
  }
}
export const checkCurrentTab = () => {
  const path = window.location.pathname.split('/')
  let tabName = path[3]
  if (!tabName || path[2] === 'support-staff') {
    return 'about-me'
  }
  return tabName
}

const userTabpermissions = {
  leaves: { group: 'leaves', entity: 'general', action: 'view_my_leaves' },
  claims: [
    { group: 'claims', entity: 'general', action: 'view_my_claims' },
    { group: 'claims', entity: 'general', action: 'create_claim' }
  ],
  subscriptions: { group: 'claims', entity: 'general', action: 'redeem_subscriptions' },
  inventory: { group: 'moveable_inventory', entity: 'items', action: 'view_items_assigned_to_me' },
  tasks: { group: 'workflows', entity: 'general', action: 'view_my_tasks' },
  projects: [
    { group: 'projects', entity: 'general', action: 'manage_projects' },
    { group: 'projects', entity: 'general', action: 'update_project' }
  ],
  dnars: { group: 'dnars', entity: 'general', action: 'show_my_points_page' }
}

export const profileSubTabs = (allPermissions, isUserProfile) => {
  let tabs = [
    { id: 0, label: 'About Me', value: 'about-me' },
    { id: 1, label: 'Personal Information', value: 'personal-information' },
    { id: 2, label: 'Job History', value: 'job-history' }
  ]
  if (checkPermission(allPermissions, 'people', 'users', 'view_sensitive_information').isFull()) {
    tabs.push({ id: 3, label: 'Documents', value: 'documents' })
  }
  if (
    checkPermission(allPermissions, 'reviews', 'general', 'user_reviews').isFull() ||
    (isUserProfile && !checkPermission(allPermissions, 'reviews', 'general', 'user_reviews').isNone())
  ) {
    tabs.splice(2, 0, { id: 11, label: 'Reviews', value: 'reviews' })
  }

  if (isUserProfile) {
    Object.entries(userTabpermissions).forEach(([key, value], index) => {
      if (!Array.isArray(value)) {
        !checkPermission(allPermissions, value.group, value.entity, value.action).isNone() &&
          tabs.push({ id: index + 4, label: startCase(key), value: key })
      } else {
        value.some(
          (tabPermission) =>
            !checkPermission(allPermissions, tabPermission.group, tabPermission.entity, tabPermission.action).isNone()
        ) && tabs.push({ id: index + 4, label: startCase(key), value: key })
      }
    })
  }
  return tabs
}

export const documentHistoryWidgetTabs = [
  { id: 1, label: 'Employee Records', value: 'Employee_records' }
  // TODO::SIGNED DOCUMENTS WILL COME WHEN ITS FUNCTIONALITY IMPLEMENTED
  // { id: 2, label: 'Signed Documents', value: 'Signed_documents' }
]

export const checkSubTab = () => {
  const path = window.location.pathname.split('/')
  if (path.length > 4) {
    let tabName = path[4]
    tabName = tabName.includes('-') ? capitalize(tabName.replace(/-/g, ' ')).replace(/ /g, '_') : capitalize(tabName)
    return tabName
  } else {
    if (path[3] === 'documents') return 'Employee_records'
    else if (path[3] === 'tickets') return 'Inbox'
    else if (path[3] === 'job-history') return 'Designation_history'
  }
}

export const offboardingTableHeader = [
  'Employee Name',
  'Title',
  'Email',
  'Tasks',
  'Exit Type',
  'Offboarding Initiated On',
  'Tentative End Date',
  'Last Working Day',
  'Status',
  'Action',
  ''
]

export const renderPayrolClaimsMessage = (hasPayrollClaims) =>
  hasPayrollClaims
    ? `Closure will be initiated for claims to be processed by payrolls with closure date ${formatDate(
        new Date()
      )}. To add any other date specifically please close the claims manual first before blocking the user`
    : ''

export const blockedFilter = { search_params: { active: true } }

export const getReportingPeopleExcellenceExecutives = (subordinates) => {
  return subordinates?.map((peopleExcellenceExecutive) => ({
    label: peopleExcellenceExecutive.name,
    value: peopleExcellenceExecutive.id,
    avatar: peopleExcellenceExecutive.avatar
  }))
}
export const getAttributesForTeamManagement = (user) => {
  return {
    id: user.id,
    name: user.name,
    avatar: user.avatar,
    title: user.title,
    reporting_to: user.reporting_to,
    lead_id: user.lead_id,
    manager_id: user.manager_id,
    people_excellence_executive_id: user.people_excellence_executive_id
  }
}

export const concatPeopleExcellenceExecutives = (
  leadReportingReopleExcellenceExecutives,
  peopleExcellenceExecutive
) => {
  return isEmpty(peopleExcellenceExecutive) ||
    checkArrayPresence(
      leadReportingReopleExcellenceExecutives.filter((value) => {
        return value.id === peopleExcellenceExecutive.id
      })
    )
    ? leadReportingReopleExcellenceExecutives
    : leadReportingReopleExcellenceExecutives.concat(peopleExcellenceExecutive)
}

export const resignationTypes = [
  { value: 'resigned', label: 'Resigned' },
  { value: 'terminated', label: 'Terminated' }
]
export const commonReligions = [
  { label: 'Islam', value: 'islam' },
  { label: 'Christianity', value: 'christianity' },
  { label: 'Hinduism', value: 'hinduism' },
  { label: 'Buddhism', value: 'buddhism' },
  { label: 'Judaism', value: 'judaism' }
]

export const closestCountries = [
  { label: 'Afghanistan', value: 'afghanistan' },
  { label: 'Albania', value: 'albania' },
  { label: 'Algeria', value: 'algeria' },
  { label: 'Andorra', value: 'andorra' },
  { label: 'Angola', value: 'angola' },
  { label: 'Antigua and Barbuda', value: 'antigua and barbuda' },
  { label: 'Argentina', value: 'argentina' },
  { label: 'Armenia', value: 'armenia' },
  { label: 'Australia', value: 'australia' },
  { label: 'Austria', value: 'austria' },
  { label: 'Azerbaijan', value: 'azerbaijan' },
  { label: 'Bahamas', value: 'bahamas' },
  { label: 'Bahrain', value: 'bahrain' },
  { label: 'Bangladesh', value: 'bangladesh' },
  { label: 'Barbados', value: 'barbados' },
  { label: 'Belarus', value: 'belarus' },
  { label: 'Belgium', value: 'belgium' },
  { label: 'Belize', value: 'belize' },
  { label: 'Benin', value: 'benin' },
  { label: 'Bhutan', value: 'bhutan' },
  { label: 'Bolivia', value: 'bolivia' },
  { label: 'Bosnia and Herzegovina', value: 'bosnia and herzegovina' },
  { label: 'Botswana', value: 'botswana' },
  { label: 'Brazil', value: 'brazil' },
  { label: 'Brunei', value: 'brunei' },
  { label: 'Bulgaria', value: 'bulgaria' },
  { label: 'Burkina Faso', value: 'burkina faso' },
  { label: 'Burundi', value: 'burundi' },
  { label: 'Cabo Verde', value: 'cabo verde' },
  { label: 'Cambodia', value: 'cambodia' },
  { label: 'Cameroon', value: 'cameroon' },
  { label: 'Canada', value: 'canada' },
  { label: 'Central African Republic', value: 'central african republic' },
  { label: 'Chad', value: 'chad' },
  { label: 'Chile', value: 'chile' },
  { label: 'China', value: 'china' },
  { label: 'Colombia', value: 'colombia' },
  { label: 'Comoros', value: 'comoros' },
  { label: 'Congo (Brazzaville)', value: 'congo brazzaville' },
  { label: 'Congo (Kinshasa)', value: 'congo kinshasa' },
  { label: 'Costa Rica', value: 'costa rica' },
  { label: "Cote d'Ivoire", value: 'cote divoire' },
  { label: 'Croatia', value: 'croatia' },
  { label: 'Cuba', value: 'cuba' },
  { label: 'Cyprus', value: 'cyprus' },
  { label: 'Czech Republic', value: 'czech republic' },
  { label: 'Denmark', value: 'denmark' },
  { label: 'Djibouti', value: 'djibouti' },
  { label: 'Dominica', value: 'dominica' },
  { label: 'Dominican Republic', value: 'dominican republic' },
  { label: 'Ecuador', value: 'ecuador' },
  { label: 'Egypt', value: 'egypt' },
  { label: 'El Salvador', value: 'el salvador' },
  { label: 'Equatorial Guinea', value: 'equatorial guinea' },
  { label: 'Eritrea', value: 'eritrea' },
  { label: 'Estonia', value: 'estonia' },
  { label: 'Eswatini', value: 'eswatini' },
  { label: 'Ethiopia', value: 'ethiopia' },
  { label: 'Fiji', value: 'fiji' },
  { label: 'Finland', value: 'finland' },
  { label: 'France', value: 'france' },
  { label: 'Gabon', value: 'gabon' },
  { label: 'Gambia', value: 'gambia' },
  { label: 'Georgia', value: 'georgia' },
  { label: 'Germany', value: 'germany' },
  { label: 'Ghana', value: 'ghana' },
  { label: 'Greece', value: 'greece' },
  { label: 'Grenada', value: 'grenada' },
  { label: 'Guatemala', value: 'guatemala' },
  { label: 'Guinea', value: 'guinea' },
  { label: 'Guinea-Bissau', value: 'guinea bissau' },
  { label: 'Guyana', value: 'guyana' },
  { label: 'Haiti', value: 'haiti' },
  { label: 'Holy See', value: 'holy_see' },
  { label: 'Honduras', value: 'honduras' },
  { label: 'Hungary', value: 'hungary' },
  { label: 'Iceland', value: 'iceland' },
  { label: 'India', value: 'india' },
  { label: 'Indonesia', value: 'indonesia' },
  { label: 'Iran', value: 'iran' },
  { label: 'Iraq', value: 'iraq' },
  { label: 'Ireland', value: 'ireland' },
  { label: 'Israel', value: 'israel' },
  { label: 'Italy', value: 'italy' },
  { label: 'Jamaica', value: 'jamaica' },
  { label: 'Japan', value: 'japan' },
  { label: 'Jordan', value: 'jordan' },
  { label: 'Kazakhstan', value: 'kazakhstan' },
  { label: 'Kenya', value: 'kenya' },
  { label: 'Kiribati', value: 'kiribati' },
  { label: 'Korea, North', value: 'korea north' },
  { label: 'Korea, South', value: 'korea south' },
  { label: 'Kosovo', value: 'kosovo' },
  { label: 'Kuwait', value: 'kuwait' },
  { label: 'Kyrgyzstan', value: 'kyrgyzstan' },
  { label: 'Laos', value: 'laos' },
  { label: 'Latvia', value: 'latvia' },
  { label: 'Lebanon', value: 'lebanon' },
  { label: 'Lesotho', value: 'lesotho' },
  { label: 'Liberia', value: 'liberia' },
  { label: 'Libya', value: 'libya' },
  { label: 'Liechtenstein', value: 'liechtenstein' },
  { label: 'Lithuania', value: 'lithuania' },
  { label: 'Luxembourg', value: 'luxembourg' },
  { label: 'Madagascar', value: 'madagascar' },
  { label: 'Malawi', value: 'malawi' },
  { label: 'Malaysia', value: 'malaysia' },
  { label: 'Maldives', value: 'maldives' },
  { label: 'Mali', value: 'mali' },
  { label: 'Malta', value: 'malta' },
  { label: 'Marshall Islands', value: 'marshall islands' },
  { label: 'Mauritania', value: 'mauritania' },
  { label: 'Mauritius', value: 'mauritius' },
  { label: 'Mexico', value: 'mexico' },
  { label: 'Micronesia', value: 'micronesia' },
  { label: 'Moldova', value: 'moldova' },
  { label: 'Monaco', value: 'monaco' },
  { label: 'Mongolia', value: 'mongolia' },
  { label: 'Montenegro', value: 'montenegro' },
  { label: 'Morocco', value: 'morocco' },
  { label: 'Mozambique', value: 'mozambique' },
  { label: 'Myanmar', value: 'myanmar' },
  { label: 'Namibia', value: 'namibia' },
  { label: 'Nauru', value: 'nauru' },
  { label: 'Nepal', value: 'nepal' },
  { label: 'Netherlands', value: 'netherlands' },
  { label: 'New Zealand', value: 'new zealand' },
  { label: 'Nicaragua', value: 'nicaragua' },
  { label: 'Niger', value: 'niger' },
  { label: 'Nigeria', value: 'nigeria' },
  { label: 'North Macedonia', value: 'north macedonia' },
  { label: 'Norway', value: 'norway' },
  { label: 'Oman', value: 'oman' },
  { label: 'Pakistan', value: 'pakistan' },
  { label: 'Palau', value: 'palau' },
  { label: 'Palestinian Territories', value: 'palestinian territories' },
  { label: 'Panama', value: 'panama' },
  { label: 'Papua New Guinea', value: 'papua new guinea' },
  { label: 'Paraguay', value: 'paraguay' },
  { label: 'Peru', value: 'peru' },
  { label: 'Philippines', value: 'philippines' },
  { label: 'Poland', value: 'poland' },
  { label: 'Portugal', value: 'portugal' },
  { label: 'Qatar', value: 'qatar' },
  { label: 'Romania', value: 'romania' },
  { label: 'Russia', value: 'russia' },
  { label: 'Rwanda', value: 'rwanda' },
  { label: 'Saint Kitts and Nevis', value: 'saint kitts and nevis' },
  { label: 'Saint Lucia', value: 'saint_lucia' },
  { label: 'Saint Vincent and the Grenadines', value: 'saint vincent and the grenadines' },
  { label: 'Samoa', value: 'samoa' },
  { label: 'San Marino', value: 'san marino' },
  { label: 'Sao Tome and Principe', value: 'sao tome and principe' },
  { label: 'Saudi Arabia', value: 'saudi arabia' },
  { label: 'Senegal', value: 'senegal' },
  { label: 'Serbia', value: 'serbia' },
  { label: 'Seychelles', value: 'seychelles' },
  { label: 'Sierra Leone', value: 'sierra leone' },
  { label: 'Singapore', value: 'singapore' },
  { label: 'Slovakia', value: 'slovakia' },
  { label: 'Slovenia', value: 'slovenia' },
  { label: 'Solomon Islands', value: 'solomon islands' },
  { label: 'Somalia', value: 'somalia' },
  { label: 'South Africa', value: 'south africa' },
  { label: 'South Sudan', value: 'south sudan' },
  { label: 'Spain', value: 'spain' },
  { label: 'Sri Lanka', value: 'sri lanka' },
  { label: 'Sudan', value: 'sudan' },
  { label: 'Suriname', value: 'suriname' },
  { label: 'Sweden', value: 'sweden' },
  { label: 'Switzerland', value: 'switzerland' },
  { label: 'Syria', value: 'syria' },
  { label: 'Taiwan', value: 'taiwan' },
  { label: 'Tajikistan', value: 'tajikistan' },
  { label: 'Tanzania', value: 'tanzania' },
  { label: 'Thailand', value: 'thailand' },
  { label: 'Timor-Leste', value: 'timor leste' },
  { label: 'Togo', value: 'togo' },
  { label: 'Tonga', value: 'tonga' },
  { label: 'Trinidad and Tobago', value: 'trinidad and tobago' },
  { label: 'Tunisia', value: 'tunisia' },
  { label: 'Turkey', value: 'turkey' },
  { label: 'Turkmenistan', value: 'turkmenistan' },
  { label: 'Tuvalu', value: 'tuvalu' },
  { label: 'Uganda', value: 'uganda' },
  { label: 'Ukraine', value: 'ukraine' },
  { label: 'United Arab Emirates', value: 'united arab emirates' },
  { label: 'United Kingdom', value: 'united kingdom' },
  { label: 'United States of America', value: 'united states of america' },
  { label: 'Uruguay', value: 'uruguay' },
  { label: 'Uzbekistan', value: 'uzbekistan' },
  { label: 'Vanuatu', value: 'vanuatu' },
  { label: 'Venezuela', value: 'venezuela' },
  { label: 'Vietnam', value: 'vietnam' },
  { label: 'Yemen', value: 'yemen' },
  { label: 'Zambia', value: 'zambia' },
  { label: 'Zimbabwe', value: 'zimbabwe' }
]

export const pakistanCitiesOptions = [
  {
    label: 'Abbottabad',
    value: 'abbottabad'
  },
  {
    label: 'Aliabad',
    value: 'aliabad'
  },
  {
    label: 'Arifwala',
    value: 'arifwala'
  },
  {
    label: 'Badin',
    value: 'badin'
  },
  {
    label: 'Bahawalnagar',
    value: 'bahawalnagar'
  },
  {
    label: 'Bahawalpur',
    value: 'bahawalpur'
  },
  {
    label: 'Bannu',
    value: 'bannu'
  },
  {
    label: 'Bara',
    value: 'bara'
  },
  {
    label: 'Barkhan',
    value: 'barkhan'
  },
  {
    label: 'Burewala',
    value: 'burewala'
  },
  {
    label: 'Chaman',
    value: 'chaman'
  },
  {
    label: 'Chilas',
    value: 'chilas'
  },
  {
    label: 'Chiniot',
    value: 'chiniot'
  },
  {
    label: 'Dadu',
    value: 'dadu'
  },
  {
    label: 'Dasu',
    value: 'dasu'
  },
  {
    label: 'Dera Allahyar',
    value: 'dera_allahyar'
  },
  {
    label: 'Dera Bugti',
    value: 'dera_bugti'
  },
  {
    label: 'Dera Ghazi Khan',
    value: 'dera_ghazi_khan'
  },
  {
    label: 'Dera Ismail Khan',
    value: 'dera_ismail_khan'
  },
  {
    label: 'Dera Murad Jamali',
    value: 'dera_murad_jamali'
  },
  {
    label: 'Diplo',
    value: 'diplo'
  },
  {
    label: 'Faisalabad',
    value: 'faisalabad'
  },
  {
    label: 'Gahkuch',
    value: 'gahkuch'
  },
  {
    label: 'Gakuch',
    value: 'gakuch'
  },
  {
    label: 'Gandava',
    value: 'gandava'
  },
  {
    label: 'Ghizer',
    value: 'ghizer'
  },
  {
    label: 'Ghotki',
    value: 'ghotki'
  },
  {
    label: 'Gilgit',
    value: 'gilgit'
  },
  {
    label: 'Gujranwala',
    value: 'gujranwala'
  },
  {
    label: 'Gujrat',
    value: 'gujrat'
  },
  {
    label: 'Gwadar',
    value: 'gwadar'
  },
  {
    label: 'Hafizabad',
    value: 'hafizabad'
  },
  {
    label: 'Hala',
    value: 'hala'
  },
  {
    label: 'Hangu',
    value: 'hangu'
  },
  {
    label: 'Haripur',
    value: 'haripur'
  },
  {
    label: 'Hyderabad',
    value: 'hyderabad'
  },
  {
    label: 'Hyderabad Cantonment',
    value: 'hyderabad_cantonment'
  },
  {
    label: 'Islamabad',
    value: 'islamabad'
  },
  {
    label: 'Jacobabad',
    value: 'jacobabad'
  },
  {
    label: 'Jamrud',
    value: 'jamrud'
  },
  {
    label: 'Jatoi',
    value: 'jatoi'
  },
  {
    label: 'Jhang',
    value: 'jhang'
  },
  {
    label: 'Jhelum',
    value: 'jhelum'
  },
  {
    label: 'Kamalia',
    value: 'kamalia'
  },
  {
    label: 'Kambar',
    value: 'kambar'
  },
  {
    label: 'Kandhkot',
    value: 'kandhkot'
  },
  {
    label: 'Karachi',
    value: 'karachi'
  },
  {
    label: 'Kasur',
    value: 'kasur'
  },
  {
    label: 'Khairpur',
    value: 'khairpur'
  },
  {
    label: 'Khanewal',
    value: 'khanewal'
  },
  {
    label: 'Khanpur',
    value: 'khanpur'
  },
  {
    label: 'Kharmang',
    value: 'kharmang'
  },
  {
    label: 'Khuzdar',
    value: 'khuzdar'
  },
  {
    label: 'Khyber',
    value: 'khyber'
  },
  {
    label: 'Kohat',
    value: 'kohat'
  },
  {
    label: 'Kohlu',
    value: 'kohlu'
  },
  {
    label: 'Kotli',
    value: 'kotli'
  },
  {
    label: 'Kotri',
    value: 'kotri'
  },
  {
    label: 'Kundian',
    value: 'kundian'
  },
  {
    label: 'Lahore',
    value: 'lahore'
  },
  {
    label: 'Lakki Marwat',
    value: 'lakki_marwat'
  },
  {
    label: 'Larkana',
    value: 'larkana'
  },
  {
    label: 'Loralai',
    value: 'loralai'
  },
  {
    label: 'Mandi Bahauddin',
    value: 'mandi_bahauddin'
  },
  {
    label: 'Mardan',
    value: 'mardan'
  },
  {
    label: 'Matiari',
    value: 'matiari'
  },
  {
    label: 'Mehrabpur',
    value: 'mehrabpur'
  },
  {
    label: 'Mian Channun',
    value: 'mian_channun'
  },
  {
    label: 'Mingora',
    value: 'mingora'
  },
  {
    label: 'Mirpur Khas',
    value: 'mirpur_khas'
  },
  {
    label: 'Moro',
    value: 'moro'
  },
  {
    label: 'Multan',
    value: 'multan'
  },
  {
    label: 'Muridke',
    value: 'muridke'
  },
  {
    label: 'Muzaffargarh',
    value: 'muzaffargarh'
  },
  {
    label: 'Nankana Sahib',
    value: 'nankana_sahib'
  },
  {
    label: 'Nawabshah',
    value: 'nawabshah'
  },
  {
    label: 'Nowshera',
    value: 'nowshera'
  },
  {
    label: 'Okara',
    value: 'okara'
  },
  {
    label: 'Pakpattan',
    value: 'pakpattan'
  },
  {
    label: 'Pano Aqil',
    value: 'pano_aqil'
  },
  {
    label: 'Parachinar',
    value: 'parachinar'
  },
  {
    label: 'Peshawar',
    value: 'peshawar'
  },
  {
    label: 'Qila Saifullah',
    value: 'qila_saifullah'
  },
  {
    label: 'Quetta',
    value: 'quetta'
  },
  {
    label: 'Rahim Yar Khan',
    value: 'rahim_yar_khan'
  },
  {
    label: 'Rahimyar Khan',
    value: 'rahimyar_khan'
  },
  {
    label: 'Rawalpindi',
    value: 'rawalpindi'
  },
  {
    label: 'Sadiqabad',
    value: 'sadiqabad'
  },
  {
    label: 'Sahiwal',
    value: 'sahiwal'
  },
  {
    label: 'Sambrial',
    value: 'sambrial'
  },
  {
    label: 'Sargodha',
    value: 'sargodha'
  },
  {
    label: 'Shahdadkot',
    value: 'shahdadkot'
  },
  {
    label: 'Shahdadpur',
    value: 'shahdadpur'
  },
  {
    label: 'Sheikhupura',
    value: 'sheikhupura'
  },
  {
    label: 'Shikarpur',
    value: 'shikarpur'
  },
  {
    label: 'Shorko',
    value: 'shorko'
  },
  {
    label: 'Sialkot',
    value: 'sialkot'
  },
  {
    label: 'Sialkot Cantonment',
    value: 'sialkot_cantonment'
  },
  {
    label: 'Sibi',
    value: 'sibi'
  },
  {
    label: 'Sukkur',
    value: 'sukkur'
  },
  {
    label: 'Surab',
    value: 'surab'
  },
  {
    label: 'Tando Allahyar',
    value: 'tando_allahyar'
  },
  {
    label: 'Tando Muhammad Khan',
    value: 'tando_muhammad_khan'
  },
  {
    label: 'Tank',
    value: 'tank'
  },
  {
    label: 'Tanks',
    value: 'tanks'
  },
  {
    label: 'Thakot',
    value: 'thakot'
  },
  {
    label: 'Thul',
    value: 'thul'
  },
  {
    label: 'Timargara',
    value: 'timargara'
  },
  {
    label: 'Turbat',
    value: 'turbat'
  },
  {
    label: 'Umerkot',
    value: 'umerkot'
  },
  {
    label: 'Usta Muhammad',
    value: 'usta_muhammad'
  },
  {
    label: 'Uthal',
    value: 'uthal'
  },
  {
    label: 'Wah Cantonment',
    value: 'wah_cantonment'
  },
  {
    label: 'Wana',
    value: 'wana'
  }
]

export const emergencyContactRelationOptions = [
  { label: 'Brother', value: 'brother' },
  { label: 'Daughter', value: 'daughter' },
  { label: 'Father', value: 'father' },
  { label: 'Friend', value: 'friend' },
  { label: 'Mother', value: 'mother' },
  { label: 'Sister', value: 'sister' },
  { label: 'Son', value: 'son' },
  { label: 'Spouse', value: 'spouse' }
]

export const basicDetailsDataFormatter = (values, isEdit, user_status, pending = false) => {
  if (!values) return values

  const newValues = cloneDeep(values)
  newValues.form_type = 'basic-details'

  const { effective_date, employment_type_id, status } = newValues

  newValues.employment_types_attributes = effective_date && [
    {
      id: employment_type_id,
      effective_date,
      status,
      tentative: new Date(effective_date) > new Date()
    }
  ]
  newValues.status = pending && isEdit ? 'pre_onboarding' : user_status || 'onboarded'

  delete newValues.effective_date
  delete newValues.confirmation_date

  return newValues
}

export const personalDetailsDataFormatter = (values, prevIdentityDocuments) => {
  let newValues = cloneDeep(values)

  let social_profiles = {}
  let attachment_bindings_attributes = []

  newValues.status = values.employment_type
  newValues.form_type = 'personal-details'
  if (values.linkedin) {
    social_profiles.linkedin = values.linkedin
    newValues.social_profiles = social_profiles
  }
  newValues.image = newValues.image ? newValues.image[0] : null

  if (newValues.cnic_front) {
    attachment_bindings_attributes.push({
      id: values.cnic_front_id,
      attachment_type: 'cnic_front_side',
      attachment_attributes: { id: newValues?.cnic_front_attachment_id, file: newValues.cnic_front[0] }
    })
  }

  if (newValues.cnic_back) {
    attachment_bindings_attributes.push({
      id: values.cnic_back_id,
      attachment_type: 'cnic_back_side',
      attachment_attributes: { id: newValues?.cnic_back_attachment_id, file: newValues.cnic_back[0] }
    })
  }

  let attachmentCnicFrontIndex = attachment_bindings_attributes.findIndex(
    (item) => item.attachment_type === 'cnic_front_side'
  )
  if (values.cnic_front && values.cnic_front[0]?.id) {
    if (attachmentCnicFrontIndex !== -1) {
      attachment_bindings_attributes.splice(attachmentCnicFrontIndex, 1)
    }
  }

  let attachmentCnicBackIndex = attachment_bindings_attributes.findIndex(
    (item) => item.attachment_type === 'cnic_back_side'
  )

  if (values.cnic_back && values.cnic_back[0]?.id) {
    if (attachmentCnicBackIndex !== -1) {
      attachment_bindings_attributes.splice(attachmentCnicBackIndex, 1)
    }
  }

  if (newValues.same_as_current_address) {
    newValues.permanent_address = newValues.current_address
  }
  newValues.attachment_bindings_attributes = attachment_bindings_attributes
  const documentIds = newValues.identity_documents_attributes.map((item) => item.id)
  const deletedIdentityDocuments = prevIdentityDocuments?.filter((item) => !documentIds?.includes(item.id))
  newValues.identity_documents_attributes = newValues.identity_documents_attributes?.map((document) => {
    const attributes = {
      id: document.id,
      document_number: document.document_number,
      identity_type: document.identity_type,
      document_setting_id: document.document_setting_id,
      attachment_bindings_attributes: []
    }

    const addAttachmentBinding = (type, documentId, documentArray, bindingId) => {
      if (checkArrayPresence(documentArray) && !documentArray[0]?.id) {
        attributes.attachment_bindings_attributes.push({
          attachment_type: type,
          attachment_attributes: { id: documentId, file: documentArray[0] }
        })
      }
      if (documentArray && documentId !== documentArray[0]?.id) {
        attributes.attachment_bindings_attributes.push({
          id: bindingId,
          attachment_type: type,
          _destroy: true
        })
      }
    }

    addAttachmentBinding(
      'identity_document_front',
      document.document_front_id,
      document.identity_document_front,
      document.identity_document_front_binding_id
    )

    addAttachmentBinding(
      'identity_document_back',
      document.document_back_id,
      document.identity_document_back,
      document.identity_document_back_binding_id
    )

    return attributes
  })

  deletedIdentityDocuments?.forEach((deletedDocument) => {
    newValues.identity_documents_attributes.push({
      id: deletedDocument.id,
      document_number: deletedDocument.document_number,
      identity_type: deletedDocument.identity_type,
      document_setting_id: deletedDocument.document_setting_id,
      _destroy: true
    })
  })

  delete newValues.linkedin
  delete newValues.cnic_front
  delete newValues.cnic_back
  return newValues
}

export const experienceDetailsDataFormatter = (values, user) => {
  let newValues = cloneDeep(values)
  if (newValues) {
    newValues.form_type = 'experience-details'
    newValues.employment_histories_attributes =
      newValues &&
      values?.employment_histories?.map((history) => {
        const attachmentBindings = [
          ...(checkArrayPresence(history.salary_slip) && !history.salary_slip[0]?.id
            ? [
                {
                  attachment_type: 'salary_slip',
                  attachment_attributes: { id: history.salary_slip_image_id, file: history.salary_slip[0] }
                }
              ]
            : []),
          ...(history.salary_slip && history.salary_slip_id !== history.salary_slip[0]?.id
            ? [
                {
                  id: history.salary_attachment_id,
                  attachment_type: 'salary_slip',
                  _destroy: true
                }
              ]
            : []),
          ...(checkArrayPresence(history.resignation_letter) && !history.resignation_letter[0]?.id
            ? [
                {
                  attachment_type: 'resignation_letter',
                  attachment_attributes: {
                    id: history.resignation_letter_image_id,
                    file: history.resignation_letter[0]
                  }
                }
              ]
            : []),
          ...(history.resignation_letter && history.resignation_letter_id !== history.resignation_letter[0]?.id
            ? [
                {
                  id: history.resignation_attachment_id,
                  attachment_type: 'resignation_letter',
                  _destroy: true
                }
              ]
            : []),
          ...(checkArrayPresence(history.experience_letter) && !history.experience_letter[0]?.id
            ? [
                {
                  attachment_type: 'experience_letter',
                  attachment_attributes: { id: history.experience_letter_image_id, file: history.experience_letter[0] }
                }
              ]
            : []),
          ...(history.experience_letter && history.experience_letter_id !== history.experience_letter[0]?.id
            ? [
                {
                  id: history.experience_attachment_id,
                  attachment_type: 'experience_letter',
                  _destroy: true
                }
              ]
            : []),
          ...(checkArrayPresence(history.resume) && !history.resume[0]?.id
            ? [
                {
                  attachment_type: 'resume',
                  attachment_attributes: { id: history.resume_image_id, file: history.resume[0] }
                }
              ]
            : []),
          ...(history.resume && history.resume_attachment_id !== history.resume[0]?.id
            ? [
                {
                  id: history.resume_id,
                  attachment_type: 'resume',
                  _destroy: true
                }
              ]
            : [])
        ]

        return {
          id: history.id,
          started_at: history.started_at,
          ended_at: history.currently_working ? null : history.ended_at,
          user_experience_company_id: history.user_experience_company_id,
          user_experience_position_id: history.user_experience_position_id,
          employment_duration: history.user_experience_employment_duration,
          attachment_bindings_attributes: attachmentBindings
        }
      })
  }
  if (user?.employment_history_data) {
    user.employment_history_data.forEach((oldHistory) => {
      let found = false
      newValues.employment_histories_attributes.some((newHistory) => {
        found = oldHistory.id === newHistory.id
        return found
      })
      if (!found)
        newValues.employment_histories_attributes.push({
          id: oldHistory.id,
          _destroy: true
        })
    })
  }
  delete newValues?.employment_histories
  delete newValues?.email

  return newValues
}

export const educationDetailsDataFormatter = (values, user) => {
  let newValues = cloneDeep(values)
  if (newValues) {
    newValues.form_type = 'education-details'
    newValues.education_details_attributes = newValues?.education?.map((educationItem) => {
      if (!educationItem.degree_image[0]?.id) {
        educationItem.attachment_binding_attributes = {
          id: educationItem.degree_attachment_binding_id,
          attachment_type: 'degree',
          attachment_attributes: { id: educationItem.degree_image_id, file: educationItem.degree_image[0] }
        }
      }
      // const isDegreeIdInOldOptions = oldDegreeOptions?.some(
      //   (option) => Number.isInteger(option.value) && option.value === educationItem.degree_id
      // )
      // const isMajorIdInOldOptions = oldMajorOptions?.some(
      //   (option) => Number.isInteger(option.value) && option.value === educationItem.major_id
      // )
      const educationDetail = {
        id: educationItem.id,
        university_id: educationItem.university_id,
        campus: educationItem.campus,
        cgpa: educationItem.cgpa,
        degree_status: educationItem.degree_status,
        start_date: educationItem.start_date,
        end_date: educationItem.end_date,
        education_detail_technologies_attributes: Array.isArray(educationItem.technology_id)
          ? educationItem.technology_id.map((item) => ({
              technology_id: item
            }))
          : [{ technology_id: educationItem.technology_id }],
        attachment_binding_attributes: educationItem.attachment_binding_attributes,
        degree_id: educationItem.degree_id,
        major_id: educationItem.major_id
      }

      // TODO :: WILL ADD THIS CODE WHEN NEW OPTION WILL BE ADDED
      // if (isDegreeIdInOldOptions) {
      //   educationDetail.degree_id = educationItem.degree_id
      // } else {
      //   educationDetail.degree_attributes = {
      //     user_id: editOtherProfile ? null : user.id,
      //     name: educationItem.degree_id,
      //     education_attribute_type: 0
      //   }
      // }

      // if (isMajorIdInOldOptions) {
      //   educationDetail.major_id = educationItem.major_id
      // } else {
      //   educationDetail.major_attributes = {
      //     user_id: editOtherProfile ? null : user.id,
      //     name: educationItem.major_id,
      //     education_attribute_type: 1
      //   }
      // }

      return educationDetail
    })
  }
  if (user?.education_details) {
    user.education_details.forEach((oldDetail) => {
      let found = false
      newValues.education_details_attributes.some((newDetail) => {
        found = oldDetail.id === newDetail.id
        if (found) {
          oldDetail.education_detail_technologies.forEach((oldTech) => {
            if (
              newDetail.education_detail_technologies_attributes.find(
                (newTech) =>
                  newTech.technology_id === oldTech.technology_id ||
                  newTech.technology_id.value === oldTech.technology_id
              )
            ) {
              const index = newDetail.education_detail_technologies_attributes.findIndex(
                (ele) =>
                  ele.technology_id === oldTech.technology_id || ele.technology_id.value === oldTech.technology_id
              )
              if (index !== -1) {
                newDetail.education_detail_technologies_attributes[index] = {
                  id: oldTech.id,
                  technology_id: oldTech.technology_id
                }
              }
            }
          })
          oldDetail.education_detail_technologies.forEach((oldTech) => {
            if (
              !newDetail.education_detail_technologies_attributes.find(
                (newTech) =>
                  newTech.technology_id === oldTech.technology_id ||
                  newTech.technology_id?.value === oldTech.technology_id
              )
            ) {
              newDetail.education_detail_technologies_attributes.push({
                id: oldTech.id,
                technology_id: oldTech.technology_id,
                _destroy: true
              })
            }
          })
        }
        return found
      })
      if (!found)
        newValues.education_details_attributes.push({
          id: oldDetail.id,
          _destroy: true
        })
    })
  }
  delete newValues?.education
  delete newValues?.email
  return newValues
}

export const degreesWithTenYearGap = [
  'Bachelor(14 Years)',
  'Matric/O-Level',
  'Intermediate/A-Level',
  'MS/M.Phil(18 Years)',
  'BS(16 Years)',
  'Doctorate'
]

export const offboardingFilters = [
  { label: 'Claims', value: 'claims' },
  { label: 'Tickets', value: 'tickets' },
  { label: 'Leaves', value: 'leaves' },
  { label: 'Leaves Encashment', value: 'leaves_encashment' },
  { label: 'Dnars', value: 'dnars' },
  { label: 'Inventory', value: 'inventory' },
  { label: 'Tasks', value: 'tasks' },
  { label: 'Active Communication', value: 'active_communication' },
  { label: 'Ticket Category', value: 'ticket_category' },
  { label: 'Department', value: 'department' }
]

export const getMinDegreeEndYear = (startDate, degree) => {
  if (startDate) {
    const currentDate = getCurrentDate()
    const differenceInYears = Math.abs(moment(startDate).diff(currentDate, 'years'))

    if (differenceInYears > 1) {
      return currentDate
    }

    if (degreesWithTenYearGap.includes(degree)) {
      return moment(startDate).add(1, 'years')
    } else {
      return getCurrentDate()
    }
  }
}

export const getMaxDegreeEndYear = (startDate, degree) => {
  if (startDate) {
    if (degreesWithTenYearGap.includes(degree)) return moment(startDate).add(10, 'years')
    else return moment().add(10, 'years')
  }
}

export const emergencyDetailsDataFormatter = (values, user) => {
  let newValues = cloneDeep(values)

  if (newValues) {
    newValues.form_type = 'emergency-details'
  }
  if (newValues?.emergency_details) {
    newValues.emergency_details_attributes = newValues.emergency_details.map((emergencyDetail) => ({
      id: emergencyDetail.id ?? null,
      name: emergencyDetail.emergency_contact_person_name,
      contact_number: emergencyDetail.emergency_contact_person_number,
      relation: emergencyDetail.emergency_contact_person_relation
    }))
  }

  if (user?.emergency_details) {
    user.emergency_details.forEach((oldEmergencyDetail) => {
      let found = false

      newValues.emergency_details_attributes.some((newDetail) => {
        found = oldEmergencyDetail.id === newDetail.id
        return found
      })
      if (!found)
        newValues.emergency_details_attributes.push({
          id: oldEmergencyDetail.id,
          _destroy: true
        })
    })
  }

  delete newValues?.emergency_details
  delete newValues?.email
  return newValues
}

export const statusDetailsDataFormatter = (values, user) => {
  let newValues = cloneDeep(values)
  newValues.form_type = 'status-details'
  newValues.title_id = user.designation_history_data[0]?.title_id
  newValues.effective_date = user.designation_history_data[0]?.effective_date

  if (newValues.employment_types) {
    newValues.employment_types_attributes = newValues.employment_types.map((employmentType) => ({
      id: employmentType.id ?? null,
      effective_date: employmentType.effective_date,
      status: employmentType.status,
      tentative: employmentType.tentative
    }))
  }

  delete newValues.employment_types
  newValues.id = user.id
  return newValues
}

export const createUserFormatter = (values, user = {}, editOtherProfile, oldMajorOptions, oldDegreeOptions) => {
  let newValues = cloneDeep(values)
  const basic_info = basicDetailsDataFormatter(newValues.basic_info, user)
  const personal_info = personalDetailsDataFormatter(newValues.personal_info)
  const emergency_info = emergencyDetailsDataFormatter(newValues.emergency, user)
  const education_info = educationDetailsDataFormatter(
    newValues.education,
    user,
    editOtherProfile,
    oldMajorOptions,
    oldDegreeOptions
  )
  const experience_info = experienceDetailsDataFormatter(newValues.experience, user)
  const dependant_info = dependantsDetailsDataFormatter(newValues.dependant, user)

  const facilities_info = newValues.facilities

  return {
    ...emergency_info,
    ...education_info,
    ...experience_info,
    ...facilities_info,
    ...dependant_info,
    ...basic_info,
    ...personal_info,
    status: 'onboarded',
    fields_fill: user.status === 'onboarded'
  }
}

export function calculateAge(dob) {
  if (!dob) return null

  const dobDate = new Date(dob)

  const currentDate = new Date() // Calculate the age
  let age = currentDate.getFullYear() - dobDate.getFullYear()

  const currentMonth = currentDate.getMonth()
  const dobMonth = dobDate.getMonth()
  const currentDay = currentDate.getDate()
  const dobDay = dobDate.getDate()

  if (currentMonth < dobMonth || (currentMonth === dobMonth && currentDay < dobDay)) {
    age--
  }

  return age
}

export function getPlaceHolder(matchingNationality) {
  return matchingNationality ? 'x'.repeat(matchingNationality?.max_length) : 'xxxxx-xxxxxxx-x'
}

export const calculateUserExperience = (userData) => {
  const currentExperience = { totalExperience: 0, cateredExperience: 0 }
  const departureDateAdjustment = userData.ending_date
    ? moment(Date.today).diff(moment(userData.ending_date), 'months')
    : 0
  currentExperience.cateredExperience += Math.round(userData.external_experience / 30)

  currentExperience.totalExperience = Math.round(userData.total_experience / 30) - departureDateAdjustment
  return currentExperience
}

export const convertMonthsToYears = (months) => {
  const duration = moment.duration(months, 'months')
  const years = duration.years()
  const remainingMonths = duration.months()
  return years === 0 ? `${remainingMonths} months` : `${years} years ${remainingMonths} months`
}

export const ticketsWidgetTabs = (userPermissions) => {
  let tabs = []
  !checkPermission(userPermissions, 'tickets', 'general', 'view_inbox_tickets').isNone() &&
    tabs.push({ id: 0, label: 'Inbox', value: 'Inbox' })
  !checkPermission(userPermissions, 'tickets', 'general', 'view_sent_tickets').isNone() &&
    tabs.push({ id: 1, label: 'Sent', value: 'Sent' })
  return tabs
}

export const jobHistoryWidgetTabs = [
  { id: 1, label: 'Designation History', value: 'Designation_history' },
  { id: 2, label: 'Status History', value: 'Employee_status_history' }
]

export const dependantsDetailsDataFormatter = (values, user) => {
  let newValues = cloneDeep(values)

  if (newValues?.dependents) {
    newValues.form_type = 'dependants-details'
    newValues.dependents_attributes = newValues.dependents.map((dependantItem) => ({
      id: dependantItem.id ?? null,
      name: dependantItem.name,
      dob: dependantItem.dob,
      relation: dependantItem.relation,
      cnic: dependantItem.cnic,
      nationality_identifier_id: dependantItem.nationality_identifier_id
    }))
  }

  if (user.dependents) {
    user.dependents.forEach((oldDependant) => {
      let found = false

      newValues.dependents_attributes.some((newDetail) => {
        found = oldDependant.id === newDetail.id
        return found
      })
      if (!found)
        newValues.dependents_attributes.push({
          id: oldDependant.id,
          _destroy: true
        })
    })
  }

  delete newValues?.dependents
  return newValues
}

export const userInOnboardingStatus = (status) => status === 'onboarding'

export const getUserOnBoardingID = (id) => {
  return id > ONBOARDING_ID_PREFIX ? `OID-${id - ONBOARDING_ID_PREFIX}` : `ID-${id}`
}

export const userHoverinfo = (name, id, offboarding_status = null) =>
  `${name} (ID-${id})${offboarding_status ? `-${offboarding_status}` : ''}`

export function formatUserIdFull(userId) {
  return 100000000 + Number(userId)
}

export function formatUserIdShort(userId) {
  return userId % 10000000
}

export const onBoardingUserID = (id) => id > ONBOARDING_ID_PREFIX

export const menuOption = (status, deleted, taskCount) => {
  if (status === 'onboarded') {
    return [{ key: 'edit', text: 'Edit' }]
  } else {
    if (deleted) {
      return [{ key: 'active', text: 'Active' }]
    }
    let options = [
      { key: 'edit', text: 'Edit' },
      { key: 'inactive', text: 'Inactive' }
    ]
    if (status !== 'pending') {
      if (taskCount !== 0) options.push({ key: 'complete_task', text: 'Complete All Task' })
      options = [
        ...options,
        { key: 'resend_invite', text: 'Resend Invite' },
        { key: 'normal_user', text: 'Move to normal user' }
      ]
    }
    return options
  }
}

export const probationsDaysList = [
  { value: `0`, label: 'N/A' },
  { value: 30, label: '30 days' },
  { value: 45, label: '45 days' },
  { value: 60, label: '60 days' },
  { value: 90, label: '90 days', selected: true },
  { value: 120, label: '120 days' },
  { value: 180, label: '180 days' }
]

export const probationsList = [
  { value: `0`, label: 'Not applicable' },
  { value: 10, label: '10 days' },
  { value: 20, label: '20 days' },
  { value: 30, label: '30 days' },
  { value: 40, label: '40 days' },
  { value: 50, label: '50 days' },
  { value: 60, label: '60 days' },
  { value: 70, label: '70 days' },
  { value: 80, label: '80 days' },
  { value: 90, label: '90 days' },
  { value: 100, label: '100 days' },
  { value: 110, label: '110 days' },
  { value: 120, label: '120 days' },
  { value: 130, label: '130 days' },
  { value: 140, label: '140 days' },
  { value: 150, label: '150 days' },
  { value: 160, label: '160 days' },
  { value: 170, label: '170 days' },
  { value: 180, label: '180 days' },
  { value: 190, label: '190 days' },
  { value: 200, label: '200 days' },
  { value: 210, label: '210 days' },
  { value: 220, label: '220 days' },
  { value: 230, label: '230 days' },
  { value: 240, label: '240 days' },
  { value: 250, label: '250 days' },
  { value: 260, label: '260 days' },
  { value: 270, label: '270 days' },
  { value: 280, label: '280 days' },
  { value: 290, label: '290 days' },
  { value: 300, label: '300 days' },
  { value: 310, label: '310 days' },
  { value: 320, label: '320 days' },
  { value: 330, label: '330 days' },
  { value: 340, label: '340 days' },
  { value: 350, label: '350 days' },
  { value: 360, label: '360 days' },
  { value: 365, label: '365 days' }
]

const allowedStatuses = [
  'offboarded_resigned',
  'offboarded_terminated',
  'offboarding_resigned',
  'offboarding_terminated'
]

export const checkDegreeStatus = (sections) => sections.education?.some((section) => !section.degree_status) ?? false

export const updatedClearanceData = (dataArray, id, key = 'id') => {
  let indexToRemove = dataArray.findIndex((data) => data[key] === id)
  dataArray.splice(indexToRemove, 1)
  return dataArray
}

const peopleDeptAndDesgOptions = (customSetter) => [
  {
    label: 'Department',
    value: 'departments',
    component: 'selectWrapper',
    placeholder: 'Select Department(s)',
    multiOption: true,
    nestedSelection: true,
    selectedValue: customSetter.selectedDepartment,
    setValue: customSetter.setSelectedDepartment,
    allCheck: true,
    nestedOptions: []
  },
  {
    label: 'Location',
    value: 'locations',
    component: 'selectWrapper',
    placeholder: 'Select Location(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Designation',
    value: 'designations',
    component: 'selectWrapper',
    placeholder: 'Select Designation(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Rank',
    value: 'title_ranks',
    component: 'selectWrapper',
    placeholder: 'Select Rank(s)',
    multiOption: true,
    allCheck: true
  }
]

export const peopleTabStatusFilter = (customSetter) => [
  {
    label: 'Status',
    value: 'statuses',
    component: 'selectWrapper',
    placeholder: 'Select Status(s)',
    selectedValue: customSetter.selectedStatus,
    setValue: customSetter.setSelectedStatus,
    multiOption: true,
    nestedSelection: true,
    allCheck: true,
    nestedOptions: [
      {
        label: 'Exit Reason',
        value: 'exit_reason_ids',
        component: 'selectWrapper',
        placeholder: 'Select Reason',
        conditionToRender:
          checkArrayPresence(customSetter.selectedStatus) &&
          customSetter.selectedStatus.some((status) => allowedStatuses.includes(status))
      }
    ]
  }
]

export const peopleTabUserTypeFilter = {
  label: 'User Type',
  value: 'user_types',
  component: 'selectWrapper',
  placeholder: 'Select Type(s)',
  multiOption: true,
  allCheck: true
}

export const peopleTabNationalityFilter = {
  label: 'Nationality',
  value: 'nationality_identifier_id',
  component: 'selectWrapper',
  placeholder: 'Select Nationality(s)',
  multiOption: true
}

export const businessUnitFilter = {
  label: 'Business Unit',
  value: 'business_unit_ids',
  component: 'selectWrapper',
  placeholder: 'Select Business Unit(s)',
  multiOption: true,
  allCheck: true
}

export const peopleTabFilters = (customSetter) =>
  [
    {
      label: 'Manager',
      value: 'managers',
      component: 'selectWrapper',
      placeholder: 'Select Manager(s)',
      userSearchable: true,
      multiOption: true,
      optionFilters: { managers: true }
    },
    {
      label: 'Joining Date Range',
      value: 'joining_date_range',
      fromDateValue: 'start_date_from',
      toDateValue: 'start_date_to',
      component: 'dateRangePickerWrapper'
    },
    {
      label: 'Technologies',
      value: 'technologies',
      component: 'selectWrapper',
      placeholder: 'Select Technology(s)',
      multiOption: true
    },
    {
      label: 'Experience',
      value: 'experiences',
      component: 'selectWrapper',
      placeholder: 'Select Experience(s)'
    },
    {
      label: 'Employment Type',
      value: 'employment_types',
      component: 'selectWrapper',
      placeholder: 'Select Employment Types',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'Lead',
      value: 'leads',
      component: 'selectWrapper',
      placeholder: 'Select Lead(s)',
      userSearchable: true,
      multiOption: true,
      optionFilters: { leads: true }
    },
    {
      label: 'Reporting To',
      value: 'reporting_to',
      component: 'selectWrapper',
      placeholder: 'Select Reporting To',
      userSearchable: true,
      multiOption: true,
      optionFilters: { reporting_to: true }
    },
    {
      label: 'Buddy',
      value: 'buddy',
      component: 'selectWrapper',
      placeholder: 'Select Buddy(s)',
      userSearchable: true,
      multiOption: true,
      optionFilters: { buddy: true }
    }
  ].concat(peopleDeptAndDesgOptions(customSetter))

export const PeoplePermissionBasedFilters = (userPermissions, currentCompany, customSetter) => {
  const finalTabFilters =
    checkPermission(userPermissions, 'people', 'users', 'block_and_offboard').isFull() ||
    checkPermission(userPermissions, 'people', 'users', 'restore_user').isFull()
      ? currentCompany.department_ranking_system
        ? peopleTabFilters(customSetter).concat(peopleTabStatusFilter(customSetter))
        : peopleTabFilters(customSetter)
            .concat(peopleTabStatusFilter(customSetter))
            .filter((opt) => opt.label !== 'Rank')
      : currentCompany.department_ranking_system
        ? peopleTabFilters(customSetter)
        : peopleTabFilters(customSetter).filter((opt) => opt.label !== 'Rank')
  const selectedFilters = (filterType) => finalTabFilters.find((object) => object.value === filterType)
  if (currentCompany.modules.support_staff && !selectedFilters('user_types')) {
    finalTabFilters.unshift(peopleTabUserTypeFilter)
  }
  if (
    checkPermission(userPermissions, 'people', 'users', 'view_sensitive_information').isFull() &&
    !selectedFilters('nationality_identifier_id')
  ) {
    finalTabFilters.unshift(peopleTabNationalityFilter)
  }
  if (
    checkHasAccessToUnits(userPermissions) &&
    currentCompany.default_business_unit &&
    !selectedFilters('business_unit_ids')
  ) {
    finalTabFilters.unshift(businessUnitFilter)
  }

  return finalTabFilters
}

export const offboardedTabFilters = (hasAccessToUnits, customSetter) => {
  let finalTabFilters = [
    {
      label: 'Status',
      value: 'statuses',
      component: 'selectWrapper',
      placeholder: 'Select Status(s)',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'Tentative End Date',
      value: 'tentative_date_range',
      fromDateValue: 'tentative_ending_date_after',
      toDateValue: 'tentative_ending_date_before',
      component: 'dateRangePickerWrapper'
    },
    {
      label: 'Resignation/Termination Date',
      value: 'resignation_date_range',
      fromDateValue: 'resigned_after',
      toDateValue: 'resigned_before',
      component: 'dateRangePickerWrapper'
    },
    {
      label: 'Claims',
      value: 'claims',
      component: 'customCheckBoxWrapper'
    },
    {
      label: 'Leaves',
      value: 'leaves',
      component: 'customCheckBoxWrapper'
    }
  ].concat(peopleDeptAndDesgOptions(customSetter))
  const selectedFilters = (filterType) => finalTabFilters.find((object) => object.value === filterType)
  if (hasAccessToUnits && !selectedFilters('business_unit_ids')) {
    finalTabFilters.unshift(businessUnitFilter)
  }
  return finalTabFilters
}

export const toolTipToggler = (matchingNationality) =>
  matchingNationality &&
  !matchingNationality?.identifier_regex &&
  'Format validation for selected nationality is not supported'

export const generateTeamChangeAttributes = (values, prevValues) => {
  const roles = {
    manager: 'project_manager',
    lead: 'lead',
    reporting: 'reporting_to',
    pse: 'people_excellence_executive'
  }
  const teamChange = []
  Object.entries(roles).forEach(([key, role]) => {
    if (
      !(
        values[`${key}_id`] === prevValues[`${key}_id`] &&
        values[`${key}_effective_date`] === prevValues[`${key}_effective_date`]
      ) &&
      !['reporting', 'pse'].includes(key)
    ) {
      teamChange.push({
        assigned_user_id: values[`${key}_id`],
        assigned_role: role,
        effective_date: values[`${key}_effective_date`],
        ...(values.currentUserId ? { assigned_by_id: values.currentUserId } : { user_id: values.id })
      })
    }
    if (
      !(
        values[`${key}_to`] === prevValues[`${key}_to`] &&
        values[`${key}_to_effective_date`] === prevValues[`${key}_to_effective_date`]
      ) &&
      key === 'reporting'
    ) {
      teamChange.push({
        assigned_user_id: values[`${key}_to`],
        assigned_role: role,
        effective_date: values[`${key}_to_effective_date`],
        ...(values.currentUserId ? { assigned_by_id: values.currentUserId } : { user_id: values.id })
      })
    }
    if (
      !(
        values['people_excellence_executive_id'] === prevValues['people_excellence_executive_id'] &&
        values['pse_effective_date'] === prevValues['pse_effective_date']
      ) &&
      key === 'pse'
    ) {
      teamChange.push({
        assigned_user_id: values['people_excellence_executive_id'],
        assigned_role: role,
        effective_date: values[`${key}_effective_date`],
        ...(values.currentUserId ? { assigned_by_id: values.currentUserId } : { user_id: values.id })
      })
    }
  })

  return teamChange
}
export const getManagerialStats = (params) => {
  if (!params.currentUserId) return

  const teamChangeHistories = []
  if (params.manager_id)
    teamChangeHistories.push({
      assigned_by_id: params.currentUserId,
      assigned_user_id: params.manager_id,
      assigned_role: 'project_manager',
      effective_date: params.manager_effective_date
    })
  if (params.lead_id)
    teamChangeHistories.push({
      assigned_by_id: params.currentUserId,
      assigned_user_id: params.lead_id,
      assigned_role: 'lead',
      effective_date: params.lead_effective_date
    })
  if (params.reporting_to)
    teamChangeHistories.push({
      assigned_by_id: params.currentUserId,
      assigned_user_id: params.reporting_to,
      assigned_role: 'reporting_to',
      effective_date: params.reporting_to_effective_date
    })
  if (params.people_excellence_executive_id)
    teamChangeHistories.push({
      assigned_by_id: params.currentUserId,
      assigned_user_id: params.people_excellence_executive_id,
      assigned_role: 'people_excellence_executive',
      effective_date: params.pse_effective_date
    })
  return teamChangeHistories
}

export const generateUserFormTeamChangeHistories = (action) =>
  action.formType === 'basic_info' ? getManagerialStats({ currentUserId: action.currentUserId, ...action.payload }) : []

export const generateTeamChangeHistories = (params, users) => {
  const mapping = {
    ...(params.manager_id && {
      manager: { assigned_role: 0, effective_date: params.manager_effective_date, assigned_user_id: params.manager_id }
    }),
    ...(params.lead_id && {
      lead: { assigned_role: 1, effective_date: params.lead_effective_date, assigned_user_id: params.lead_id }
    }),
    ...(params.reporting_to && {
      reporting: {
        assigned_role: 2,
        effective_date: params.reporting_to_effective_date,
        assigned_user_id: params.reporting_to
      }
    }),
    ...(params.people_excellence_executive_id && {
      pse: {
        assigned_role: 3,
        effective_date: params.pse_effective_date,
        assigned_user_id: params.people_excellence_executive_id
      }
    })
  }

  let team_change_histories_attributes = []
  users.forEach((user) => {
    Object.entries(mapping).forEach(([key, value]) => {
      if (params[key]) {
        if (key !== 'pse') {
          team_change_histories_attributes.push({
            user_id: user.id,
            ...value
          })
        }
        if (key === 'pse' && params.pse_effective_date) {
          team_change_histories_attributes.push({
            user_id: user.id,
            ...value
          })
        }
      }
    })
  })

  return team_change_histories_attributes
}
