import dotProp from 'dot-prop-immutable'

import * as types from 'actions/actionTypes'
import { totalItems } from 'helpers/applicationHelper'
import { currentUserRemovedAndParticipantsExists } from 'helpers/ticketsHelper'

import initialState from './initialState'

export const ticket = (state = initialState.tickets, action) => {
  let object = {}
  let index
  let ticketIndex = null
  let updateTicketApprovals = []
  let searchTickets
  let categoryIndex,
    categories = null

  switch (action.type) {
    case types.FETCH_TICKETS_SUCCESS:
      return {
        ...state,
        createdTickets: action.payload,
        ticketFetched: true
      }

    case types.FETCH_TICKETS_FAILURE:
      return state

    case types.FETCH_TICKET_SUCCESS:
      return { ...state, ticket: action.payload }

    case types.FETCH_TICKET_FAILURE:
      return state

    case types.FETCH_ASSIGNED_TICKETS_SUCCESS:
      return {
        ...state,
        assignedTickets: action.payload,
        ticketFetched: true
      }

    case types.FETCH_ASSIGNED_TICKETS_FAILURE:
      return state

    case types.CREATE_TICKET_SUCCESS:
      sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) + 1)
      return { ...state, createdTickets: [action.payload, ...state.createdTickets] }

    case types.CREATE_TICKET_FAILURE:
      return state

    case types.UPDATE_TICKET_SUCCESS:
      return { ...state, ticket: action.payload.ticket }

    case types.UPDATE_TICKET_FAILURE:
      return state

    case types.UPDATE_TICKET_STATUS_SUCCESS:
      ticketIndex = state.assignedTickets.findIndex((ticket) => ticket.id === action.payload.id)

      object = {}
      if (ticketIndex !== -1) {
        let assignedTickets = [...state.assignedTickets]
        assignedTickets[ticketIndex] = action.payload
        object = Object.assign({}, object, { assignedTickets: [...assignedTickets] })
      }

      ticketIndex = state.createdTickets.findIndex((ticket) => ticket.id === action.payload.id)

      if (ticketIndex !== -1) {
        let createdTickets = [...state.createdTickets]
        createdTickets[ticketIndex] = action.payload
        object = Object.assign({}, object, { createdTickets: [...createdTickets] })
      }

      ticketIndex = state.ticket.ticket_approvals?.findIndex(
        (approval) => approval.id === action.payload.ticket_approval.id
      )

      if (ticketIndex !== -1 && 'ticket_approval' in action.payload) {
        updateTicketApprovals = [...state.ticket.ticket_approvals]
        updateTicketApprovals[ticketIndex] = action.payload.ticket_approval
        object = Object.assign({}, object, {
          ticket: {
            ...state.ticket,
            ticket_approvals: updateTicketApprovals,
            ticket_events: action.payload.ticket_event
              ? [...state.ticket.ticket_events, action.payload.ticket_event]
              : [...state.ticket.ticket_events]
          }
        })
      }

      return { ...state, ...object }

    case types.UPDATE_SHOW_TICKET_STATUS_SUCCESS:
      if (action.payload.last_comment) {
        if (state.ticketComments[action.payload.last_comment.commentable_id]) {
          return {
            ...state,
            ticket: action.payload,
            ticketComments: {
              ...state.ticketComments,
              [action.payload.last_comment.commentable_id]: [
                ...state.ticketComments[action.payload.last_comment.commentable_id],
                action.payload.last_comment
              ]
            }
          }
        }
        return {
          ...state,
          ticket: action.payload,
          ticketComments: {
            ...state.ticketComments,
            [action.payload.last_comment.commentable_id]: [action.payload.last_comment]
          }
        }
      }
      return { ...state, ticket: action.payload }

    case types.UPDATE_TICKET_STATUS_FAILURE:
      return state

    case types.FETCH_TICKET_COMMENTS_SUCCESS:
      if (action.payload[0]) {
        return {
          ...state,
          ticketComments: { ...state.ticketComments, [action.payload[0].commentable_id]: action.payload }
        }
      }
      return state

    case types.FETCH_TICKET_COMMENTS_FAILURE:
      return state

    case types.CREATE_TICKET_COMMENT_SUCCESS:
      if (state.ticketComments[action.payload.comment.commentable_id]) {
        return {
          ...state,
          ticketComments: {
            ...state.ticketComments,
            [action.payload.comment.commentable_id]: [
              ...state.ticketComments[action.payload.comment.commentable_id],
              action.payload.comment
            ]
          },
          ticket: {
            ...state.ticket,
            participants: action.payload.ticket_users,
            ticket_events: [...state.ticket.ticket_events, ...action.payload.ticket_events]
          }
        }
      }
      return {
        ...state,
        ticketComments: { ...state.ticketComments, [action.payload.comment.commentable_id]: [action.payload.comment] },
        ticket: {
          ...state.ticket,
          participants: action.payload.ticket_users,
          ticket_events: [...state.ticket.ticket_events, ...action.payload.ticket_events]
        }
      }

    case types.CREATE_TICKET_COMMENT_FAILURE:
      return state

    case types.FETCH_SEARCHED_TICKETS_SUCCESS:
      return {
        ...state,
        searchedTickets: action.payload,
        ticketFetched: true
      }

    case types.FETCH_SEARCHED_TICKETS_FAILURE:
      return state

    case types.FETCH_TICKET_CSV_ATTRIBUTES_SUCCESS:
      return { ...state, csvAttributes: action.payload }

    case types.FETCH_TICKET_CSV_ATTRIBUTES_FAILURE:
      return state

    case types.FETCH_TICKETS_CSV_DATA_SUCCESS:
      return { ...state, csvData: action.payload }

    case types.FETCH_TICKETS_CSV_DATA_FAILURE:
      return state

    case types.SET_FILTERED_TICKETS:
      return { ...state, filteredTickets: action.payload }

    case types.UPDATE_USER_COMMENTS_SUCCESS:
      if (state.createdTickets.length !== 0 || state.assignedTickets.length !== 0) {
        const ticketId = action.payload.commentable_id

        if (state.createdTickets.length !== 0) {
          if (action.payload.commentable_owner) {
            for (let createdTicket in state.createdTickets) {
              if (parseInt(state.createdTickets[createdTicket].id, 10) === parseInt(ticketId, 10))
                return dotProp.set(state, `createdTickets.${createdTicket}.unread_comments`, 0)
            }
          }
        }

        if (state.assignedTickets.length !== 0) {
          for (let assignedTicket in state.assignedTickets) {
            if (parseInt(state.assignedTickets[assignedTicket].id, 10) === parseInt(ticketId, 10)) {
              return dotProp.set(state, `assignedTickets.${assignedTicket}.unread_comments`, 0)
            }
          }
        }
      }

      return state

    case types.UPDATE_USER_COMMENTS_FAILURE:
      return state

    case types.FETCH_TICKET_STATS_SUCCESS:
      return { ...state, ticketStats: action.payload }

    case types.FETCH_TICKET_STATS_FAILURE:
      return state

    case types.CHANGE_TICKET_PARTICIPANTS_SUCCESS:
      object = {}

      if (action.payload.ticket.id) {
        ticketIndex = state.assignedTickets.findIndex((ticket) => ticket.id === action.payload.ticket.id)

        if (ticketIndex !== -1) {
          let allAssignedTickets = [...state.assignedTickets]

          if (
            state.assignedTickets.findIndex((ticket) => ticket.assignee?.id !== action.payload.ticket.assignee?.id) !==
            -1
          ) {
            let userIds = action.payload.ticket.participants.map((user) => user.user.id)

            if (userIds.includes(state.assignedTickets[ticketIndex].assignee?.id)) {
              allAssignedTickets[ticketIndex] = action.payload.ticket
            } else {
              allAssignedTickets.splice(ticketIndex, 1)
            }
          } else {
            allAssignedTickets[ticketIndex] = action.payload.ticket
          }
          if (currentUserRemovedAndParticipantsExists(action.payload.ticket, action.payload.userId)) {
            allAssignedTickets.splice(ticketIndex, 1)
          }

          object = Object.assign({}, object, { assignedTickets: [...allAssignedTickets] })
        }

        ticketIndex = state.createdTickets.findIndex((ticket) => ticket.id === action.payload.ticket.id)

        if (ticketIndex !== -1) {
          let allCreatedTickets = [...state.createdTickets]

          if (
            state.createdTickets.findIndex((ticket) => ticket.creator.id !== action.payload.ticket.creator.id) !== -1
          ) {
            let userIds = action.payload.ticket.participants.map((user) => user.user.id)

            if (userIds.includes(state.allCreatedTickets[ticketIndex].creator.id)) {
              allCreatedTickets[ticketIndex] = action.payload.ticket
            } else {
              allCreatedTickets.splice(ticketIndex, 1)
            }
          } else {
            allCreatedTickets[ticketIndex] = action.payload.ticket
          }

          if (currentUserRemovedAndParticipantsExists(action.payload.ticket, action.payload.userId)) {
            allCreatedTickets.splice(ticketIndex, 1)
          }

          object = Object.assign({}, object, { createdTickets: [...allCreatedTickets] })
        }
        ticketIndex = state.searchedTickets.findIndex((ticket) => ticket.id === action.payload.ticket.id)

        if (state.ticket && state.ticket.id === action.payload.ticket.id) {
          object = Object.assign({}, object, { ticket: action.payload.ticket })
        }

        if (ticketIndex !== -1) {
          searchTickets = [...state.searchedTickets]
          searchTickets[ticketIndex] = action.payload.ticket

          if (currentUserRemovedAndParticipantsExists(action.payload.ticket, action.payload.userId)) {
            searchTickets.splice(ticketIndex, 1)
          }

          return {
            ...state,
            searchedTickets: searchTickets,
            ...object
          }
        }
      }

      return { ...state, ...object }

    case types.CHANGE_TICKET_PARTICIPANTS_FAILURE:
      return state

    case types.FETCH_TICKET_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload.sort((a, b) => a.name.localeCompare(b.name)) }

    case types.FETCH_TICKET_CATEGORIES_FAILURE:
      return state

    case types.CREATE_TICKET_CATEGORY_SUCCESS:
      return { ...state, categories: [...state.categories, ...action.payload] }

    case types.CREATE_TICKET_CATEGORY_FAILURE:
      return state

    case types.UPDATE_TICKET_CATEGORY_SUCCESS:
      index = state.categories.findIndex((cat) => cat.id === action.payload.id)
      state.categories[index] = action.payload

      return { ...state, categories: [...state.categories], ticketCategory: {} }

    case types.UPDATE_TICKET_CATEGORY_FAILURE:
      return state

    case types.CLEAR_TICKET:
      return { ...state, ticket: {} }

    case types.CLEAR_TICKET_FETCHED:
      return { ...state, ticketFetched: false }

    case types.SHOW_TICKET_LOADER:
      return { ...state, ticketLoader: true }

    case types.HIDE_TICKET_LOADER:
      return { ...state, ticketLoader: false }

    case types.HANDLE_TICKET_SEARCH_CHANGE:
      return { ...state, search: { ...state.search, ...action.payload } }

    case types.FETCH_ASSIGNABLE_USERS_SUCCESS:
      return { ...state, assignableUsers: action.payload }

    case types.FETCH_ASSIGNABLE_USERS_FAILURE:
      return state

    case types.FETCH_TICKET_CATEGORY_SUCCESS:
      return { ...state, ticketCategory: action.payload }

    case types.FETCH_TICKET_CATEGORY_CHANGE_LOGS_SUCCESS:
      return { ...state, changeLogs: action.payload }

    case types.FETCH_TICKET_CATEGORY_CHANGE_LOGS_FAILURE:
      return { ...state, changeLogs: [] }

    case types.FETCH_TICKET_CATEGORY_FAILURE:
      return state

    case types.DELETE_TICKET_CATEGORY_SUCCESS:
      if (action.payload.id) {
        categoryIndex = state.categories.findIndex((category) => category.id === action.payload.id)
      }

      categories = [...state.categories]
      categories.splice(categoryIndex, 1)

      return { ...state, categories: [...categories] }

    case types.DELETE_TICKET_CATEGORY_FAILURE:
      return state

    case types.FETCH_TICKET_ACTIONS_SUCCESS:
      return { ...state, actions: action.payload }

    case types.CREATE_TICKET_ACTIONS_SUCCESS:
      sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) + action.payload.length)
      return { ...state, actions: [...state.actions, ...action.payload] }

    case types.DELETE_TICKET_ACTIONS_SUCCESS:
      sessionStorage.setItem('totalItems', parseInt(totalItems(), 10) - 1)
      return { ...state, actions: state.actions.filter((ticket) => ticket.id !== action.payload.id) }

    case types.FETCH_ALL_SCHEDULES_EMAILS_SUCCESS:
      return { ...state, scheduleEmails: action.payload }

    case types.CREATE_SCHEDULE_EMAIL_SUCCESS:
      return { ...state, scheduleEmails: [...state.scheduleEmails, action.payload] }

    case types.DELETE_SCHEDULE_EMAIL_SUCCESS:
      return { ...state, scheduleEmails: state.scheduleEmails.filter((schedule) => schedule.id !== action.payload.id) }

    default:
      return state
  }
}
