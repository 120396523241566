import { toastr } from 'react-redux-toastr'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { replace, startCase } from 'lodash'
import { Tooltip } from 'maple-storybook'
import { InfoRounded } from 'maple-storybook/assets'
import {
  checkArrayEmpty,
  checkArrayPresence,
  collectionIntoSelectOptions,
  totalItems,
  webView
} from 'maple-storybook/utils'
import moment from 'moment'
import CustomToastr from 'views/Toastr/CustomToastr'

import { AMS_PORT } from 'config/apiConfig'
import { DATE_FORMAT, EMAIL_FORMAT } from 'helpers/constants'

import * as TimeAgoConfig from '../config/timeAgoConfig'

import { AMS_IFRAME_ID } from './constants'
import { editOtherProfile, invitePeople, isAccountOwner } from './userProfileHelper'

export {
  checkPermission,
  checkValueNull,
  checkValueType,
  collectionIntoSelectOptionsWithImages,
  emptyFilterTypeAndValue,
  extractDefaultPermissions,
  flattenObj,
  formatLongText,
  getFiltersClearValue,
  getUserOnBoardingID,
  isNumber,
  onBoardingUserID,
  trimmedLength
} from 'maple-storybook/utils'

export { checkArrayEmpty, checkArrayPresence, collectionIntoSelectOptions, totalItems, webView }

export const errorsAlert = ({ errors, trace_id }, default_msg = null) => {
  if (trace_id) {
    toastr.error(errors[0], {
      timeOut: 10000,
      component: <CustomToastr errors={errors} trace_id={trace_id} />
    })
  } else {
    errors
      ? typeof errors === 'object'
        ? errors.map((error) => toastr.error(error))
        : toastr.error(errors)
      : toastr.error(default_msg)
  }
}

export const concatNotApplicableOption = (options) =>
  [{ label: 'Not Applicable', value: '0', avatar: null }].concat(options)

export const pseudoProfileText = (title, id, timeline = false) => `${title} (PPID-${id}) ${timeline ? 'Thread' : ''}`

export const invalidTextLength = (value) => value.length < 3 && value.length > 0

export const validTextLength = (value, displayHint) => (value.length >= 3 || value.length === 0) && displayHint

export const isImpersonateMode = () => localStorage.getItem('impersonate') === 'true'

export const warningAlert = (warnings) => {
  if (warnings) {
    typeof warnings === 'object' ? warnings.map((warning) => toastr.warning(warning)) : toastr.warning(warnings)
  }
}

export const DOMAIN = window.location.hostname.split('.')[0]

export const timesAgo = (date) => {
  TimeAgo.locale(en)
  const timeAgo = new TimeAgo('en-US')
  return startCase(timeAgo.format(new Date(date), TimeAgoConfig.timeStyle))
}

export const intervalToSeconds = (interval) => {
  let parts = interval.split(':')
  return parseInt(parts[0]) * 60 * 60 + parseInt(parts[1]) * 60
}

export const capitalize = (string) => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const fullUserName = (firstName, lastName) => {
  return firstName + ' ' + lastName
}

export const sortValue = (value) => value.sort((a, b) => a.label.localeCompare(b.label))

export const formatObjectIntoSelectValue = (label, value) => ({ label: label, value: value })

export const addClass = (parent, newClass) => elementByClass(parent) && elementByClass(parent).classList.add(newClass)

export const addClassOnTag = (parent, newClass) => document.getElementsByTagName(parent)[0].classList.add(newClass)

export const removeClass = (parent, oldClass) =>
  elementByClass(parent) && elementByClass(parent).classList.remove(oldClass)

export const removeClassOnTag = (parent, newClass) =>
  document.getElementsByTagName(parent)[0].classList.remove(newClass)

const elementByClass = (parent) => document.getElementsByClassName(parent)[0]

export const toggleClass = (parent, className, flag) =>
  flag === true ? addClass(parent, className) : removeClass(parent, className)

export const allowedBirthDate = () =>
  new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDate())

export const formatErrors = (errors) => {
  let formatedErrors = errors.includes('Validation failed') ? errors.substring(19).split(',') : errors.split(',')
  formatedErrors = formatedErrors.map((formatedError) => formatedError.trim())
  return formatedErrors
}

export const generateUUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (value) =>
    //eslint-disable-next-line
    (value ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (value / 4)))).toString(16)
  )
}

export const secondsToInterval = (seconds) => {
  return new Date(1000 * seconds).toISOString().substr(11, 5)
}

export const formatDate = (date) => {
  return moment(new Date(date)).format(DATE_FORMAT)
}

export const secondsToHoursAndMinutes = (seconds) => {
  let hours = parseInt(seconds / 3600)
  let minutes = parseInt((seconds / 3600 - hours) * 60)
  return `${hours}h ${minutes} m`
}

export const handleNotApplicableValue = (input, label) => {
  if (label && Array.isArray(input.value) && input.value.filter((option) => option.label === label).length > 0) {
    input.value = input.value.filter((option) => option.label === label)[0]
  }
}

export const extractFormKeys = (obj, prefix = '') =>
  Object.keys(obj).reduce((arr, key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (!isNaN(key)) {
        return [...arr, ...extractFormKeys(obj[key], `${prefix}[${key}].`)]
      }
      return [...arr, ...extractFormKeys(obj[key], prefix + key)]
    }
    return [...arr, prefix + key]
  }, [])

export const convertToLowerCase = (text) => text.toLowerCase()

export const replaceSpaceWithHyphen = (text) => text.replace(/\s/g, '-')

export const replaceUnderscoreWithHyphen = (text) => text.replace(/_/g, '-')

export const uniqueArrayValues = (value, index, array) => array.indexOf(value) === index

export const fileSizeConverter = (bytes) => {
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

export const paginationItemsCount = (pageSize) => Math.ceil(totalItems() / pageSize + 1) - 1

export const addHyphenToCnicNumber = (number) =>
  [number.substring(0, 5), number.substring(5, 12), number.substring(12, number.length)].join('-')

export const removeHyphenToPhoneNumber = (number) => number.replace(/-/g, '')

export const addHyphenToPhoneNumber = (number) => {
  number = removeHyphenToPhoneNumber(number)
  return [number.substring(0, 4), number.substring(4, number.length)].join('-')
}

export const customRedirectHelper = (url) => {
  if (url.includes('claims/?')) {
    return replace(url, 'claims/?', 'claims?')
  } else if (url.includes('/claims/?approval/')) {
    return replace(url, '/claims/?approval/', '/claims/approvals?approval/')
  } else {
    return url
  }
}

export const removeProfilePartFromURL = (url) => {
  let updatedURL = ''
  if (url.includes('tickets/')) {
    updatedURL = url.replace(/\/profile\/\d+\/tickets\//, '/ticket/')
    return updatedURL.replace(/tickets\//g, 'ticket/')
  } else {
    return url.replace(/\/profile\/\d+\/tickets/, '/tickets')
  }
}

export const extractPath = (url) => {
  let updatedUrl = ''
  if (url.includes('tickets') && url.includes('profile')) {
    updatedUrl = removeProfilePartFromURL(url)
  } else {
    updatedUrl = url
  }
  const newURL = customRedirectHelper(updatedUrl)
  const splittedURL = newURL.split('/')

  let extractedURL = ''
  splittedURL.map(
    (value, index) =>
      index + 3 < splittedURL.length && (extractedURL = extractedURL.concat(`${splittedURL[index + 3]}/`))
  )
  return extractedURL
}

export const currencyOptions = [
  { label: 'USD', value: 'usd' },
  { label: 'PKR', value: 'pkr' }
]

export const wordsAcronym = (text) => text.split(/\s/).reduce((accumulator, word) => accumulator + word.charAt(0), '')

export const tabsPermissionRedirection = (tabsPermissions, allTabs, setCurrentTab, basePath) => {
  if (!tabsPermissions[0] && window.location.pathname === basePath) {
    for (let index = 0; index < tabsPermissions.length; index++) {
      if (tabsPermissions[index] && allTabs[index].href !== window.location.pathname) {
        setCurrentTab(allTabs[index].href)
        window.history.pushState(null, '', `${allTabs[index].href}`)
        break
      } else if (tabsPermissions[index] && allTabs[index].href === window.location.pathname) {
        break
      }
    }
  }
}

export const formatLevelsIntoOptions = (titleState) =>
  collectionIntoSelectOptions(
    titleState.allTitles.filter((level) => level.parent_id),
    'id',
    'name'
  )

export const formatTitlesIntoOptions = (titleState) =>
  titleState.allTitles.map((title) => ({
    ...title,
    value: title.id,
    label: title.name,
    department_id: title.department_id
  }))

export const filteredTitles = (selectedDepartment, designations) =>
  checkArrayPresence(selectedDepartment)
    ? designations.filter((title) => selectedDepartment.includes(title.department_id))
    : designations

export const formatObjectIntoOptions = (employmentTypes) =>
  Object.values(employmentTypes).map((empType) => ({ value: empType, label: transformString(empType) }))

export const transformString = (string) => string.humanize().titleize()

export const getDefaultPageSize = () => (webView() ? 50 : 10)

export const labelWithInfoIcon = (label, infoText) => {
  return (
    <>
      {label}
      <Tooltip title={infoText} place="right" effect="solid">
        <InfoRounded fontSize="10" className="ml-half info-icon" />
      </Tooltip>
    </>
  )
}

export const scrollIntoViewHelper = (errors) => {
  const firstError = errors[0]
  let selectedField = ''
  if (document.querySelector(`[name="${firstError}"]`)?.className?.includes('MuiInputBase-input')) {
    selectedField = document.querySelector(`[name="${firstError}"]`)
  } else {
    selectedField = document.querySelector(`[id="${firstError}"]`)
  }

  if (selectedField) {
    selectedField.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }
}

export const handleSelectAllItems = (
  totalItems,
  setCurrentItems,
  allSelected,
  setAllSelected,
  errorMessage = 'No Items to Select'
) => {
  let newItems = []
  if (allSelected) {
    setAllSelected(false)
  } else {
    if (checkArrayEmpty(totalItems)) errorsAlert(errorMessage)
    else {
      totalItems.forEach(({ id }) => newItems.push(id))
      setAllSelected(true)
    }
  }
  setCurrentItems(newItems)
}

export const handleSingleSelectItem = (id, selectedItems, setAllSelected, totalItems, setCurrentItems) => {
  let newItems = [...selectedItems]
  if (newItems.includes(id)) {
    newItems = newItems.filter((item) => item !== id)
    setAllSelected(false)
  } else {
    newItems.push(id)
    if (newItems.length === totalItems.length) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }
  setCurrentItems(newItems)
}

export const genericApprovalOptions = [
  { value: 1, label: 'Approved' },
  { value: '0', label: 'Pending' },
  { value: 2, label: 'Rejected' }
]

export const bloodGroupFormat = (input) => input.replace(/_negative/g, ' -').replace(/_positive/g, ' +')

export const generateNavigationString = (id, array) => {
  return `${array.indexOf(id) + 1} / ${array.length}`
}

export const achievedPercentage = (total, completed) => ((total - completed) / total) * 100

export const achievedTotal = (total, completed) => `${total - completed}/${total}`

export const paidFeatureText = (feature, companyName) => `No ${feature} can be added as ${companyName} is on free plan`

export const findStrings = (errors, rowNumber) => {
  if (!Array.isArray(errors)) {
    return [`row #${rowNumber + 1}: ${errors}`]
  }
  let filteredErrors = errors
    .filter((str) => str.includes(`row #${rowNumber + 1}:`))
    .map((error) => `${error.split(`row #${rowNumber + 1}: `)[1]}, `)
  checkArrayPresence(filteredErrors) && filteredErrors.unshift(`Row #${rowNumber + 1}: `)
  return filteredErrors.length > 0 ? filteredErrors : ''
}

export const extractNumber = (str) => {
  const match = str.match(/\[(\d+)\]/)
  return match ? parseInt(match[1], 10) : null
}

export const validateEmailFormat = (values) => {
  let regex = EMAIL_FORMAT
  let validEmail = new RegExp(regex)

  return validEmail.test(values)
}

export const downloadSampleCsv = (oldHeaders, filename) => {
  let headers = Object.values(oldHeaders)
    .filter((value) => value.hasOwnProperty('label'))
    .map((value) => value.label)
  const csvData = headers.join(',') + '\n'
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export const getAmsOrigin = (companySubdomain) => {
  const isProdEnv = process.env.NODE_ENV === 'production'

  return `${isProdEnv ? 'https://' : 'http://'}${companySubdomain}.${
    isProdEnv ? 'maplehr' : 'localhost'
  }.io${isProdEnv ? '' : ':' + AMS_PORT}`
}

export const handleModalOffset = (sidebarOpen, companySubdomain) => {
  const amsIframe = document.getElementById(AMS_IFRAME_ID)

  amsIframe.contentWindow.postMessage(
    {
      message: {
        height: window.innerHeight,
        scrollPosition: Math.floor(document.documentElement.scrollTop),
        show: webView(),
        sidebarOpen
      },
      action: 'setModalOffset'
    },
    getAmsOrigin(companySubdomain)
  )
  document.body.style.overflow = webView() ? 'hidden' : 'auto'
}

export const handleReceiveMessage = (event, navigate, setShowBlurOverlay, sidebarOpen, companySubdomain) => {
  const amsOrigin = getAmsOrigin(companySubdomain)

  if (event.origin === amsOrigin) {
    const {
      data: { path, action, message }
    } = event
    const amsIframe = document.getElementById(AMS_IFRAME_ID)

    switch (action) {
      case 'navigate':
        return navigate(path)
      case 'showBlurOverlay':
        document.body.style.overflow = webView() ? 'hidden' : 'auto'
        amsIframe.contentWindow.postMessage(
          {
            message: {
              height: window.innerHeight,
              scrollPosition: Math.floor(document.documentElement.scrollTop),
              show: webView(),
              sidebarOpen
            },
            action: 'setModalOffset'
          },
          amsOrigin
        )
        return setShowBlurOverlay(true)
      case 'hideBlurOverlay':
        amsIframe.contentWindow.postMessage(
          {
            message: {
              show: false
            },
            action: 'hideModalOffset'
          },
          amsOrigin
        )
        document.body.style.overflow = 'auto'
        return setShowBlurOverlay(false)
      case 'pushState':
      case 'replaceState':
        return window.history[action](null, '', path)
      case 'error':
      case 'success':
        return toastr[action](message)
      case 'setHeight': {
        amsIframe.style.height = `${message}px`
        return
      }
    }
  }
}

export const setLocalStorageItems = (key, value) => localStorage.setItem(key, value)

export const getLocalStorageItems = (key) => localStorage.getItem(key)

export const removeLocalStorageItems = (key) => localStorage.removeItem(key)

export const dynamicApprovalOptions = [
  { label: 'Dynamic Approvals', value: 'dynamic_approvals_required' },
  { label: 'Lead Approval', value: 'lead_approval_required' },
  { label: 'PM Approval', value: 'pm_approval_required' }
]

export const checkHasAccessToUnits = (permissions) =>
  invitePeople(permissions) || editOtherProfile(permissions) || isAccountOwner(permissions)

export const getRankOptions = (allTitleRanks) => {
  let uniqueTitleRanksMap = new Map()

  allTitleRanks?.forEach((title) => {
    const titleRank = title.title_rank
    if (titleRank && titleRank.rank !== undefined) {
      uniqueTitleRanksMap.set(titleRank.rank, titleRank)
    }
  })

  uniqueTitleRanksMap = Array.from(uniqueTitleRanksMap.values())
  uniqueTitleRanksMap.sort((a, b) => a.rank - b.rank)
  return uniqueTitleRanksMap
}

export const getLevelOptions = (titles, ranks) =>
  titles?.filter((title) => title?.title_rank?.rank !== undefined && ranks.includes(title?.title_rank.rank)) ?? []

export const avatarImageProps = (offboarded = false) => {
  return { loading: 'lazy', style: { objectFit: 'contain', opacity: offboarded ? '0.5' : '1' } }
}
