import { stringify } from 'qs'

import { loadingProjectList } from 'actions/project'

import { serialize } from './helpers'
import request from './request'

export const createProject = (params, successAction, failureAction) => (dispatch) => {
  let projectParams = { project: params }

  dispatch(
    request(
      'projects/',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json'
        },
        body: serialize(projectParams),
        submission: true
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const createPseudoProfile = (param, successAction, failureAction) => {
  let profileName = { pseudo_profile: param }

  return request(
    'pseudo_profiles',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: serialize(profileName),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}
export const createPseudoDesignation = (param, successAction, failureAction) => {
  let designationName = { hiring_designation: param }

  return request(
    'hiring_designations',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: serialize(designationName),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const updatePseudoProfile = (id, params, successAction, failureAction) => {
  return request(
    `pseudo_profiles/${id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ pseudo_profile: params })
    },
    successAction,
    failureAction,
    true
  )
}

export const updatePseudoDesignation = (id, params, successAction, failureAction) => {
  return request(
    `hiring_designations/${id}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json'
      },
      body: serialize({ hiring_designation: params })
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchPseudoProfiles =
  (page, perPage, columnName, direction, params, successAction, failureAction) => (dispatch) => {
    let queryParams = {
      page,
      page_size: perPage,
      order: columnName,
      direction,
      search_params: params
    }
    dispatch(
      request(
        `pseudo_profiles?${stringify(queryParams, { arrayFormat: 'brackets' })}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        },
        successAction,
        failureAction,
        true
      )
    )
  }

export const fetchPseudoDesignations =
  (page, perPage, columnName, direction, params, successAction, failureAction) => (dispatch) => {
    let queryParams = {
      page,
      page_size: perPage,
      order: columnName,
      direction,
      search_params: params
    }

    dispatch(
      request(
        `hiring_designations?${stringify(queryParams, { arrayFormat: 'brackets' })}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        },
        successAction,
        failureAction,
        true
      )
    )
  }

export const fetchProjects = (params, successAction, failureAction) => (dispatch) => {
  dispatch(loadingProjectList())
  dispatch(
    request(
      `projects?${stringify(params, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const generateProjectReport = (params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `projects/advanced_reports?${stringify(params, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const fetchProject = (id, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `projects/${id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const updateProject = (params, successAction, failureAction, threadParams = null) => {
  let projectParams = { project: params }
  if (threadParams) {
    projectParams = { ...threadParams }
  }

  let serializedParams = params.image ? serialize(projectParams) : JSON.stringify(projectParams)
  let acceptableHeaders = params.image
    ? { Accept: 'application/json' }
    : {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }

  return request(
    `projects/${params.id}`,
    {
      method: 'PUT',
      headers: acceptableHeaders,
      body: serializedParams,
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const deleteProject = (params, successAction, failureAction) => {
  return request(`projects/${params}`, { method: 'DELETE' }, successAction, failureAction, true)
}

export const updateProjectStatus = (status_params, project_params, successAction, failureAction) => (dispatch) => {
  let newProjectStatus = {
    project_status: {
      project_id: status_params.id,
      status: status_params.status,
      start_date: status_params.start_date,
      confirmation: status_params.confirmation
    },
    project: project_params
  }

  dispatch(
    request(
      `projects/${status_params.id}/statuses/`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newProjectStatus),
        submission: true
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const updateThreadStatus = (params, successAction, failureAction) => (dispatch) => {
  let threadId = params.pseudo_profile_status.project_pseudo_profile_id
  dispatch(
    request(
      `project_pseudo_profile/${threadId}/statuses/`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
        submission: true
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const fetchUsersProjects = (successAction, failureAction) => {
  return request('users/projects', {}, successAction, failureAction, true)
}

export const fetchProjectMembers = (id, active, successAction, failureAction) => {
  return request(`projects/${id}/members/?active=${active}`, {}, successAction, failureAction, true)
}

export const deleteProjectMember = (id, project_id, successAction, failureAction) => {
  return request(`projects/${project_id}/members/${id}`, { method: 'DELETE' }, successAction, failureAction, true)
}

export const fetchUserProjects = (id, successAction, failureAction) => {
  return request(`users/projects/${id}`, {}, successAction, failureAction, true)
}

export const fetchUserActiveProjects = (id, active, successAction, failureAction) => {
  return request(`users/projects/${id}?active=${active}`, {}, successAction, failureAction, true)
}

export const createProjectMember = (id, params, successAction, failureAction) => {
  return request(
    `projects/${id}/members`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ member: params }),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const updateProjectMember = (id, params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `projects/${id}/members/${params.member_id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ member: params }),
        submission: true
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const fetchCsvData = (successAction, failureAction) => {
  return request(`project_invoices/`, {}, successAction, failureAction, true)
}
export const uploadCsvInvoice = (params, parseWithoutSaving, successAction, failureAction) => {
  return request(
    `project_invoices?parse_without_saving=${parseWithoutSaving}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: serialize(params),
      submission: true
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchMonthlyInvoiceData = (params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `project_invoices/show?${stringify(params, { arrayFormat: 'brackets' })}`,
      {},
      successAction,
      failureAction,
      true
    )
  )
  dispatch(loadingProjectList())
}

export const fetchInvoiceCsvAttributes = (successAction, failureAction) =>
  request('project_invoices/allowed_attributes', {}, successAction, failureAction, true)

export const fetchUsersForProject = (successAction, failureAction) => {
  return request('admin/users/no_project', {}, successAction, failureAction, true)
}

export const fetchUserProjectMembers = (id, active, successAction, failureAction) => {
  return request(`projects/${id}/members/?active=${active}`, {}, successAction, failureAction, true)
}

export const fetchUsersForProjectModule = (successAction, failureAction) => {
  return request('users/projects/all_users', {}, successAction, failureAction, true)
}

export const fetchUserProjectsForClaims = (user_projects, successAction, failureAction) => {
  return request(`users/projects?${stringify({ user_projects })}`, {}, successAction, failureAction, true)
}

export const fetchProjectCsvAttributes = (successAction, failureAction) => {
  return request('members/allowed_attributes', {}, successAction, failureAction, true)
}

export const fetchProjectCsvData = (params, attributes, successAction, failureAction) => {
  return request(
    `members?${stringify(params, { arrayFormat: 'brackets' })}&attributes=${attributes}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchProjectEligibleMembers = (params, successAction, failureAction) => (dispatch) => {
  dispatch(
    request(
      `user_allowance_eligibilities?${stringify(params, { arrayFormat: 'brackets' })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      successAction,
      failureAction,
      true
    )
  )
}

export const fetchSelectPseudoProfiles = (params, successAction, failureAction) => {
  return request(
    `project_pseudo_profile?${stringify(params, { arrayFormat: 'brackets' })}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchCountries = (successAction, failureAction) => {
  return request(
    `countries`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    successAction,
    failureAction,
    true
  )
}

export const fetchProjectServices = (successAction, failureAction) => {
  return request('project_services', {}, successAction, failureAction, true)
}
