import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Breadcrumbs, Grid, Typography } from 'maple-storybook'
import { KeyboardArrowLeft, KeyboardArrowRight } from 'maple-storybook/assets'

import { fetchDepartmentsListFailure, fetchDepartmentsListSuccess } from 'actions/department'
import { createQuestionFailure, createQuestionSuccess } from 'actions/question'
import { fetchDepartmentsList } from 'api/department'
import { newQuestion } from 'api/question'
import { RegularCard } from 'components'
import { collectionIntoSelectOptions } from 'helpers/settingsHelper'

import QuestionForm from './QuestionForm'

const CreateQuestion = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    props.fetchDepartmentsList()
  }, [])

  const handleSubmit = (question) => {
    props.newQuestion(question)
    navigate('/faqs')
  }

  return (
    <div className="my-regular-card">
      <RegularCard
        cardTitle={<b>Add FAQs</b>}
        cardSubtitle="Submit your FAQs for convenience of the clients."
        breadCrumbs
        breadCrumbsContent={
          <Grid container alignItems="center" className="mt-1 ml-half">
            <KeyboardArrowLeft className="cursor-pointer" onClick={() => navigate(-1)} fontSize="small" />
            <Breadcrumbs separator={<KeyboardArrowRight fontSize="small" />}>
              <Typography clickable variant="subtitle2" color="gray-500" onClick={() => navigate('/faqs')}>
                FAQs
              </Typography>
              <Typography variant="subtitle2" color="primary-900">
                Add New
              </Typography>
            </Breadcrumbs>
          </Grid>
        }
        content={<QuestionForm isNew={true} departments={props.departments} onSubmit={handleSubmit} />}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  newQuestion: (params) => {
    dispatch(newQuestion(params, createQuestionSuccess, createQuestionFailure))
  },
  fetchDepartmentsList: (_) => {
    dispatch(fetchDepartmentsList(fetchDepartmentsListSuccess, fetchDepartmentsListFailure))
  }
})

const mapStateToProps = (state) => ({
  departments: collectionIntoSelectOptions(state.departments.departmentsList, 'id', 'name'),
  submission: state.formSubmission.submission
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestion)
